<template>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner">
          <!-- Modal Content -->
          <slot />
          <div v-show="showClose" @click="close" class="close">
              <img class="button-img" alt="Start Button" src="/img/arena-button.png">
              <div class="close-text">CLOSE</div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["modalActive", "showClose"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };
    return { close };
  },
};
</script>

<style scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(43 43 43 / 70%);
  z-index: 2000;
}

.modal-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    width: 8%;
    padding-top: 18px;
}

.close:hover{
    transform: scale(105%);
    transition: transform 0.2s ease-in-out;
}

.close-text {
    font-size: 1.8vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position: relative;
    width: 80%;
    transform: translate(12%, -150%);
}
    

</style>