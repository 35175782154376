    <template>
        <div>
            <p>
                {{ days }}<strong>D</strong> {{ hours }}<strong>H</strong> {{ minutes }}<strong>M</strong> {{ seconds }}<strong>S</strong>
            </p>
        </div>
    </template>
    <script>
    export default {
        name: 'Countdown',
    mounted() {
        //Get the current time
        this.intervalId = window.setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);

            const timeToGo = this.dateInMilliseconds - this.now;
            
            if( timeToGo <= 0 )
            {
                window.clearInterval(this.intervalId);
                this.$emit("time:finished")
            }
            
        }, 1000);
    },
    props: {
        date: {
            type: String
        }
    },
    data() {
        return {
            //Want to return the current time for the computed actions
            now: Math.trunc((new Date()).getTime() / 1000),
            intervalId: 0
        }
    },
    computed: {
        //Get Date in milliseconds - Use this to calculate days hours etc left
        dateInMilliseconds() {
            return Math.trunc(this.date)
        },
        seconds() {
            return (this.dateInMilliseconds - this.now) % 60;
        },
        minutes() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
        },
        hours() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
        },
        days() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
        }
    },
}
</script>
<style scoped>
    div{
        font-size: 42px;
    }

    p{
        margin-top: 0;
    }
</style>