<template>
    <div class="bottom-toggles">
        <div :class="'back-button ' + this.backButtonClass">
                <router-link to="/arena-menu">
                    <img class="back-button-img" alt="Arena Button" src="/img/arena-button.png">
                    <div class="back-button-text">BACK</div>
                </router-link>
        </div>
        <div class="toggle-buttons">
            <div class="toggle-img">
                <img @click="$emit('sfx-switch')"  alt="sfx trigger" :src="sfxEnabled ? sfxSrcEnabled : sfxSrcDisabled">
            </div>
            <div class="toggle-img">
                <img @click="$emit('music-switch')"  alt="music trigger" :src="musicEnabled ? musicSrcEnabled : musicSrcDisabled">
            </div>
        </div>
    </div>
</template>
<script>


export default {
  name: 'BottomToggles',
  props: {
    currentUser: {},
    sfxEnabled: Boolean,
    musicEnabled: Boolean,
    backButtonClass: String
  },
  data() {
    return{ 
        sfxSrcEnabled: "/img/button-sfx-enabled.png",
        sfxSrcDisabled: "/img/button-sfx-disabled.png",
        musicSrcEnabled:"/img/button-music-enabled.png",
        musicSrcDisabled:"/img/button-music-disabled.png",
    }
  }
}
</script>

<style scoped>

.back-button {
    bottom: 30px;
    left: 12px;
    width: 8%;
    position: absolute;
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.back-button:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

.back-button-img{
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
}

.back-button-text{
    padding-left: 6px;
    font-family: "Godzilla";
    font-size: 2.3vw;
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -40%);
}

.toggle-buttons {
    display: flex;
    position: absolute;
    bottom: 0px;
    right: 12px;
    justify-content: flex-end;
}

.toggle-img{
    width: 25%;
    transition: transform 0.2s ease-in-out;
}

.toggle-img:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

.hide-items {
    visibility: hidden;
}

/* MOBILE VIEW */

    @media only screen and (max-width: 600px) {
        .back-button{
            width: 20%;
        }

        .back-button-text{
            font-size: 4.3vw;
        }
    }

</style>