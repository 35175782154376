<template>


    <div class="main-font">

      <div class="introduction">
        <img alt="Random Tiny Dragon" :src="tinyDragonImage" class="top-tiny-dragon">
        <div class="embedded-youtube">
          <div>Introducing Tiny Dragons</div>
          <iframe width="360" height="215" src="https://www.youtube.com/embed/LLjYct1Lt6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      
        </div>
      </div>
    
      <div class="quote-div">
        <div>
          " TINY DRAGONS IS A SET OF 1000 UNIQUE NFTS, GAMES AND ACTIVITIES EXCLUSIVELY ON THE AVALANCHE C-CHAIN "
        </div>
      </div>
      <div class="battle-info">
          <div>Face off in the arena!</div>
          <div class="battle-info-inner">
            <router-link to="/arena-menu" >
              <img class="dragon-arena-img clickable-tiny" alt="dragon arena" src="/img/dragon-arena.png" >
            </router-link>
            <div>Battle in tournament for your chance to win $DCAU or partner coins</div>
          </div>
      </div>
      <div class="dragon-levelup">
          <div>Grow your dragon's power</div>
          <div>
            <div class="dragon-levelup-decsription">Build your fighting machine. Level up your tiny dragon using $DCAU and unlock higher tier matches with bigger rewards!</div>
            <router-link to="/my-dragons" >
              <img class="dragon-levelup-img clickable-tiny" alt="Dragon level up" src="/img/dragon-levelup.png" >
            </router-link>
          </div>
      </div>
      <div class="dragon-fire">
          <div>Win tiny dragons + $prize</div>
          <div class="dragon-fire-inner">
            <router-link to="/lottery" >
              <img class="dragon-fire-img clickable-tiny" alt="dragon fire" src="/img/dragon-fire-lottery.png" >
            </router-link>
            <div>Participate in the fire lottery, burn $DCAU, win tiny dragons and prizes in $USDT.E AND MORE!</div>
          </div>
      </div>
    </div>
    <div :class="showLoading ? 'loading-cloud' : 'loading-cloud hide'">
          <div class="close" @click="hideLoader()">Close</div>
          <div class="element bounce">
            <p>Processing...</p>
          </div>
    </div>
</template>

<script>

export default {
  name: 'Home',
  props: {
    currentUser: {},
    hasApproved: Boolean,
  },
  inheritAttrs: false,
  components: {
  },
  data() {
    return{
      tinyDragonImage: null,
      showLoading: Boolean,
    }
  },
  methods: {
    hideLoader(){
      this.showLoading = false
    },
    stopMusic(){
      this.$emit("stop-music")
    },
    showNavBar(){
      this.$emit("show-navbar")
    }
  },
  created(){
    this.tinyDragonImage = `/dr/g1/trans/${Math.floor(Math.random() * (1000 - 1 + 1) + 1)}trans.png`
    this.showLoading = false
  },
  mounted(){
    this.stopMusic()
    this.showNavBar()
  }
}
</script>

<style>
.top-tiny-dragon{
  width: 430px;
  transform: scaleX(-1);
  position: absolute;
  z-index: -1;
  padding-left: 360px;
}

.embedded-youtube{
  padding-left: 269px;
}

.clickable-tiny {
  transition: transform 0.2s ease-in-out;
}

.clickable-tiny:hover {
  transform: scale(110%);
  transition: transform 0.2s ease-in-out;
}

.main-font{
  font-family: 'Komika Axis';
  font-size: 32px;
  color: rgb(66,179,207);
}

.introduction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px;
}

.quote-div{
  display: flex;
  justify-content: center;
  background: rgb(66,179,207);
  padding: 60px;
}

.quote-div div{
  font-size: 22px;
  width: 50%;
  color: white;  
}

.battle-info{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.battle-info-inner {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.battle-info-inner a{
  display: contents;
}

.battle-info-inner div{
  font-size: 18px;
  width: 55%;
}

.dragon-arena-img{
  width: 300px;  
  object-fit: contain;
}

.dragon-levelup {
  background: rgb(66,179,207);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.dragon-levelup div{
  width: 60%;
  color: white;  
  display: flex;
  justify-content: center;
  padding: 18px;
}

.dragon-levelup a{
  display: contents;
}

.dragon-levelup-decsription{
  font-size: 18px;
}

.dragon-levelup-img{
  width: 220px;
  object-fit: contain;
}

.dragon-fire{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.dragon-fire-inner {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragon-fire-inner div{
  font-size: 18px;
  width: 60%;
}

.dragon-fire-inner a{
  display: contents;
}

.dragon-fire-img{
  width: 300px;  
  object-fit: contain;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
{
  .quote-div div{
    width: 90%;
  }

  .battle-info-inner {
    width: 100%;
    display: block;
  }

  .dragon-levelup div{
    width: 100%;
    display: block;
  }

  .dragon-fire-inner {
    width: 100%;
    display: block;
  }

  .top-tiny-dragon{
    position: unset;
    padding: 0px;
  }

  .embedded-youtube{
    position: unset;
    padding: 0px;
  }
}

</style>