<template>
    <button v-if="this.enabled" @click="onClick()" :class="color">{{text}}</button>    
    <button v-else disabled="disabled" :class="color">{{text}}</button>  
</template>
<script>
export default {
        name: 'Header',
        props: {
            text: String,
            color: String,
            enabled: Boolean
        },
        methods: {
            onClick(){
                this.$emit('btn-click')
            }
        }
}
</script>
<style scoped>
    button:disabled{
        border-color: gray;
        background: silver;
    }
</style>