<template>
    <div class='match-item' v-if="dragon.tokenId !== '0'">
        <div class="dragon-holder">
            <img alt="My Tiny Dragon" src="img/square-bg.png">
            <div class="user-dragon">
                <div v-if="Object.keys(dragon).length == 0" class="stats">LOADING</div>
                <div v-else class="stats">
                    #{{dragon.tokenId}} L{{dragon.currentLevel}} A{{ dragon.currentAttack}} D{{dragon.currentDefense}}
                </div>
                <img v-if="Object.keys(dragon).length == 0" src="/indicator/loading.gif"  class="user-dragon-img">
                <img @click="$emit('toggle-modal'); $emit('select-dragon', dragon)" v-else :src="'/dr/g1/' + dragon.tokenId + '.png'" class="user-dragon-img" alt="Tiny Dragon">
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'UserDragon',
  props: {
    currentUser: {},
    dragon: {}
  }, 
}
</script>

<style >
.dragon-holder {
    position: relative;
}

.stats{
    font-size: 0.8vw;
    font-family: fantasy;
    padding-bottom: 6px;
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    transform: translate(0%, -5%);
    letter-spacing: 1.3px;
}

 .user-dragon {
    position:absolute;
    width: 80%;
    display: block;
    margin: auto;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%)
} 


.user-dragon-img{
    border-radius: 12px;
}

.match-item{
    width: 12%;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.match-item:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

</style>