<template>
    <div>
      <div class="arena-content">
        <div class="toast-wrapper">
          <Toaster v-for="(match) in this.toastMatches" :item="match" :key="match.matchId"
            :match="match"
          />
        </div>
        <ArenaNavBar 
            @hide-navbar="$emit('hide-navbar')"
            @show-navbar="$emit('show-navbar')" 
            :currentUser="this.currentUser"
            :userDragons="userDragons"
            :selectedDragon="selectedDragon"
            @select-dragon="changeSelectedDragon"
            />
        <div class="toggles">
          <label class="switch">
            <span class="active-text">ACTIVE</span>
            <input @click="showActive = !showActive" type="checkbox" checked>
            <span class="slider round"></span>
          </label>
          <label class="switch">
            <span class="entered-toggle-text">ENTERED</span>
            <input @click="showEntered = !showEntered" type="checkbox">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="matches" v-if="matches.length > 0">
         
          <!-- <div class="pagin-button left-paging-button">
            <img v-show="matches.length > 3" @click="scrollLeft" class="previous-img" alt="previous Button" :src="previousSrc">
          </div> -->
          <MatchItem
            v-for="(match) in this.matches" :key="match.id"
            v-show="this.checkIfShow(match)"
            :match="match"
            :selectedDragon="selectedDragon"
            :currentUser="currentUser"
            :showActive="showActive"
            :showEntered="showEntered"
            :userDragons="userDragons"
            @show-toast="showToast"
          />
          <!-- <div class="pagin-button right-paging-button">
            <img v-show="matches.length > 3" @click="scrollRight" class="next-img" alt="next Button" :src="nextSrc">
          </div> -->
        </div>
        <div class="no-match" v-else>
            Loading match list...
        </div>
       
      </div>
        <ArenaScene 
        :distanceBackground="mountainsLower"
        :trees="hideItems"
        :ground="groundLower"
        :sfxEnabled="sfxEnabled"
        :musicEnabled="musicEnabled"
        @sfx-switch="$emit('sfx-switch')"
        @music-switch="$emit('music-switch')"
        />
    </div>
</template>
<script>

import ArenaScene from '../components/ArenaScene'
import ArenaNavBar from '../components/ArenaNavBar'
import MatchItem from '../components/MatchItem'
import Toaster from '../components/Toaster'

export default {
  name: 'ArenaMatches',
  props: {
    currentUser: {},
    sfxEnabled: Boolean,
    musicEnabled: Boolean,
    userDragons: Array,
    selectedDragon: {},
    matches: Array //this should be used
  },
  components: {
      ArenaScene,
      ArenaNavBar,
      MatchItem,
      Toaster
  },
  data() {
    return{ 
      hideItems: "hide-items",
      mountainsLower: "mountains-lower",
      groundLower: "ground-lower",
      limitStart: 0,
      limitEnd: 3,
      previousSrcEnabled: "/img/button-previous-enabled.png",
      previousSrcDisabled: "/img/button-previous-disabled.png",
      nextSrcEnabled:"/img/button-next-enabled.png",
      nextSrcDisabled:"/img/button-next-disabled.png",
      previousSrc: "",
      nextSrc: "",
      showActive: true,
      showEntered: false,
      showLoading: true,
      numberOfDisplayedMatches: 0,
      toastMatches: []
    }
  },
  methods:{
      showToast(match){
        if(this.toastMatches.find(toastMatch => toastMatch.matchId === match.matchId) === undefined){
          this.toastMatches.push(match)
        }
      },
      changeSelectedDragon(dragon){
          this.$emit('select-dragon', dragon)
      },
      isUserEntered(match){
        // console.log(match.matchTokenIds)

        for (const dragon of this.userDragons) {
          const tokenIdString = dragon.tokenId + ""
          
          return match.matchTokenIds.includes(tokenIdString)
        }
      },
      checkIfShow(match){
        if ( !this.showActive && match.isFinished && this.isUserEntered(match) && this.showEntered )
        {
          return true;
        }

        if( this.showEntered && !this.isUserEntered(match) )
        {
          return false;
        }

        if( this.showEntered && this.isUserEntered(match) )
        {
          if( this.showActive && match.isFinished )
            return false;
          
          return true;
        }  

        if( !this.showActive && match.isFinished )
        {
          return true;
        }

        if (this.showActive && !match.isFinished) {
          return true;
        }
      }
  },
  created() {
    this.previousSrc= this.previousSrcDisabled
    this.nextSrc= this.nextSrcEnabled
  },
  mounted(){
      this.$emit("play-music")
      
  }
}
</script>

<style>
.arena-content{
    display: flex;
    position: absolute;
    z-index: 10;
    flex-direction: column;
    align-items: center;
}

.toast-wrapper{
    width: 360px;
    padding: 30px 20px;
    position: absolute;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.no-match{
    font-size: 3.5vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position: absolute;
    top: 140%;
}

.maintenance{
  position: absolute;
  z-index: 20;
  top: 32vh;
  font-size: 2.5vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.matches {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 55vh;
    width: 100%;
    overflow-y: scroll;
}
.pagin-button{
  width: 5% ;
  transition: transform 0.2s ease-in-out;
}

.left-paging-button{
  position: relative;
  left: 12px;
}

.right-paging-button{
  position: relative;
  right: 12px;
}

.pagin-button:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

.toggles{
  padding-bottom: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 19px;
  left: 4px;
  bottom: 1.5px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.active-text{
  position: absolute;
  right: 105%;
 
}

.entered-toggle-text{
  position: absolute;
  left: 105%;
}

</style>