<template>
    <h2 class="page-heading">Viewing #{{ this.dragonId }}</h2>
    <div v-if="this.isOwner">
        <h3>You own this Tiny Dragon!</h3>
    </div>

    <div class="container">
        <div class="card">
            <h2>{{ this.name }}</h2>
            <a :href="'/dr/g1/' + this.dragonId + '.png'" target="_blank">
                <img :src="'/dr/g1/' + this.dragonId + '.png'" alt="Tiny Dragon" />
            </a>
            <h3>Level {{ this.currentLevel }}</h3>
            <ul>
                <li><div class="img-box"><img src="/img/attack-icon.png" alt="Attack" /></div> <div class="text">{{ this.currentAttack }} Attack</div></li>
                <li><div class="img-box"><img src="/img/rank-icon.png" alt="Rank" /></div> <div class="text">{{ this.rank }}/1000 Rank</div></li>
                <li><div class="img-box"><img src="/img/defense-icon.png" alt="Defense" /></div> <div class="text">{{ this.currentDefense }} Defense</div></li>

                <li v-if="this.hasArenaRank"><div class="img-box"><img src="/img/arena-logo.png" alt="Arena Rank" /></div> <div class="text">&nbsp; No. {{ this.arenaRank }} Arena Rank</div></li>
            </ul>
            <div v-if="this.isOwner">
                <template v-if="this.hasMigrated">
                    <Button :text="'Level up!'" @btn-click="showLevelUp" :color="'primary'" :enabled="true" />
                </template>
                <template v-else>
                    <Button :text="'Migrate Levels to DCAU!'" @btn-click="doMigrate" :color="'primary'" :enabled="true" />
                </template>
            </div>
            <a class="button" target="_blank" :href="'https://app.nftrade.com/assets/avalanche/0x2144a0eb052dd991ec8f34b6a46d2d2eb765c026/' + this.dragonId">Trade on NFTrade</a>
            <a class="button" target="_blank" :href="'https://exchange.yetiswap.app/#/nft-detail/0x2144A0eB052DD991Ec8f34b6a46D2d2Eb765C026/' + this.dragonId">Trade on Yeti Swap</a>
        </div>
        <div class="attributes">
            <h2>Attributes</h2>
            <ul>
                <li class="title">
                    <div class="name">Attribute Name</div> <div class="percent">How Common</div>
                </li>
                <li v-for="item in this.attributes" :key="item.name">
                    <div class="name">{{ item.name }}</div> <div class="percent">{{ item.percent }}%</div>
                </li>
            </ul>
        </div>
    </div>
    <div :class="isShowingLevelUp ? 'loading-cloud large' : 'loading-cloud large hide'">
            <div class="close" @click="hideLevelUp()">Close</div>
            <div class="element">
                <div class="level-content">
                    <h3>Level up your Tiny Dragon</h3>
                    <template v-if="this.freeLevels <= 0">
                        <p>Price: $30 - <strong>{{ ( ( this.levelUpPriceInNative / (10**18)) ).toFixed(2) }} DCAU </strong><br/>BAL: <strong>{{ ( ( this.nativeBalance / (10**18)) ).toFixed(2) }} DCAU</strong></p>
                     </template>
                    <p class="error" v-if="this.isError">
                        {{this.errorMessage}}
                    </p>
                    <form>
                        <div class="form-item"><label for="attack">Attack</label><input @input="handleInput" v-model="this.levelUpAttack" id="attack" name="attack" class="attack-points" type="number" max="2" min="0" /></div>
                        <div class="form-item"><label for="defense">Defense</label><input @input="handleInput" v-model="this.levelUpDefense" id="defense" name="defense" class="defense-points" type="number" max="2" min="0" /></div>
                    </form>
                    <template v-if="this.freeLevels <= 0">
                        <Button v-if="this.hasApproved" :text="'Level Up (Cost ' + ( ( this.levelUpPriceInNative / (10**18)) ).toFixed(2) + ' DCAU)'" @btn-click="buyLevelUp" :color="'primary'" :enabled="!this.isError" />
                        <Button v-else :text="'Approve DCAU'" @btn-click="approveUser" :color="'primary'" :enabled="!this.isError" />
                    </template>
                    <template v-else>
                        <Button :text="'Redeem Level Up'" @btn-click="buyLevelUp" :color="'primary'" :enabled="!this.isError" />
                    </template>
                </div>
            </div>
    </div>

    <div :class="isLoading ? 'loading-cloud' : 'loading-cloud hide'">
        <div class="close" @click="hideLoader()">Close</div>
        <div class="element bounce">
            <p>Processing...</p>
        </div>
    </div>

    <div :class="showLevelUpConfirmation ? 'loading-cloud level-confirm' : 'loading-cloud hide'">
        <div class="close" @click="hideLevelUpConfirm()">Close</div>
        <div class="element">
            <div>
            <h3>You have levelled up!</h3>
            <p>You have successfully levelled up your Tiny Dragon. Congratulations!</p>
            <Button :enabled="true" @btn-click="hideLevelUpConfirm()" :text="'Close'" />
            </div>
        </div>
    </div>
</template>
<script>
import tinyDragonAbi from '../abi/tinyDragon.json'
import arenaAbi from '../abi/tinyDragonArena.json'
import Web3 from 'web3';
import constants from '../consts/constants'
import Button from '../components/Button'
import BigNumber from 'bignumber.js'
import dcauAbi from '../abi/erc20.json'
import { ethers } from 'ethers'

export default {
  name: 'View',
  props: {
    currentUser: {},
    currentRankings: Array
  },
  components: {
      Button
  },
  data() {
    return{ 
        rarityScores: {
            "Green": 662,
            "Body": 1000,
            "Face": 773,
            "Plates": 976,
            "Spots": 274,
            "Slate Highlights": 78,
            "AVAX Background": 814,
            "Cute Eyes": 649,
            "Tail": 676,
            "Horns": 712,
            "Pink Highlights": 601,
            "Sky Background": 50,
            "Straw Hat": 66,
            "Wings": 566,
            "Trident Tail": 102,
            "Spooky Background": 20,
            "Green Mohawk": 22,
            "Crazy Eyes": 102,
            "Bat Wings": 187,
            "Puckered Up": 31,
            "Fanned Tail": 45,
            "Flame Eyes": 23,
            "Orange": 59,
            "The Grin": 52,
            "Yellow Highlights": 82,
            "Purple Background": 33,
            "Angry Eyes": 63,
            "Tail Flame": 17,
            "Long Horns": 29,
            "Zombie": 60,
            "Zombie Damage": 60,
            "Double Horns": 63,
            "Black": 6,
            "Dead Eyes": 46,
            "Pink": 80,
            "Stripes": 162,
            "Northern Lights Background": 35,
            "Spiked Tail": 98,
            "Scales": 166,
            "Green Highlights": 83,
            "Content": 144,
            "Lair Background": 26,
            "Feathered Wings": 99,
            "Extra Feathery": 89,
            "Extra Scaley": 71,
            "Blue": 64,
            "Black Skull Mask": 35,
            "Bone": 24,
            "Skull Mask": 74,
            "Red": 47,
            "Opal Highlights": 42,
            "Purple": 36,
            "Blade Tail": 57,
            "Purple Mohawk": 12,
            "Black Highlights": 39,
            "Closed Eyes": 48,
            "Bloodshot Dead Eyes": 43,
            "Tail Blue Flame": 17,
            "Plated Tail": 22,
            "Grey": 22,
            "Blue Flame Eyes": 26,
            "Bone Wings": 25,
            "Eggshell Hat": 26,
            "White Highlights": 51,
            "Blue Mohawk": 3,
            "Stars Background": 22,
            "Red Mohawk": 2
        },
        attributes: [],
        isOwner: false,
        dragonId: 0,
        name: '',
        rank: 0,
        dragonMetaData: {},
        currentLevel: "Not Connected",
        levelUpPriceInNative: new BigNumber(0),
        tinyDragonsContract: {},
        freeLevels: 0,
        arenaContract: {},
        currentAttack: "Not Connected",
        currentDefense: "Not Connected",
        isShowingLevelUp: false,
        levelUpAttack: 1,
        levelUpDefense: 1,
        isError: false,
        isLoading: false,
        showLevelUpConfirmation: false,
        hasApproved: false,
        nativeBalance: new BigNumber(0),
        hasArenaRank: false,
        arenaRank: 0,
        hasMigrated: false
    }
  },
  methods:{
      getJSON( url, callback ){
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'json'
        xhr.onload = function() {
            var status = xhr.status
            if (status === 200) {
                callback(xhr.response)
            } else {
                callback(xhr.response)
            }
        }
        xhr.send()
      },
      showLevelUp()
      {
        this.arenaContract.methods.levelUpTokens( this.dragonId ).call().then( r => {
            this.freeLevels = r
            this.isShowingLevelUp = true
        })
          
      },
      doMigrate()
      {
          this.isLoading = true
          this.arenaContract.methods.migrateStats( this.dragonId ).send( { from: this.currentUser.walletAddress }  ).then( ()=> {
              this.hasMigrated = true
              this.isLoading = false

              this.$emit('refresh-dragon')
           })
      },
      buyLevelUp(){
        this.isShowingLevelUp = false
        this.isLoading = true


        this.arenaContract.methods.levelUp( this.dragonId, this.levelUpAttack, this.levelUpDefense )
        .send({ from: this.currentUser.walletAddress })
        .then( () => { 
            this.isLoading = false

            this.currentLevel = (this.currentLevel * 1) + 1 
            this.currentAttack = ( this.currentAttack * 1 ) + ( this.levelUpAttack * 1 )
            this.currentDefense = ( this.currentDefense * 1 ) + ( this.levelUpDefense * 1 )

            this.levelUpAttack = 1
            this.levelUpDefense = 1

            this.$emit('refresh-dragon')

            this.arenaContract.methods.levelUpTokens( this.dragonId ).call().then( r => {
                this.freeLevels = r
            })

            this.showLevelUpConfirmation = true            
        })
    },
      hideLevelUp()
      {
          this.isShowingLevelUp = false
      },
      handleInput()
      {
        if ((this.levelUpAttack + this.levelUpDefense) > 2) {
            this.errorMessage = "You're only allowed to spend 2 points in total."
            this.isError = true
        } else if( ( this.levelUpAttack + this.levelUpDefense) < 2 ){
            this.errorMessage = "You must spend at least 2 points."
            this.isError = true
        } else {
            this.isError = false
        }
      },
      hideLoader()
      {
          this.isLoading = false
      },
      hideLevelUpConfirm()
      {
          this.showLevelUpConfirmation = false
      },
      approveUser(){
        this.isLoading = true
        const web3 = new Web3(window.ethereum);
        const dcauContract = new web3.eth.Contract(dcauAbi, constants.dcauContract);

        dcauContract.methods.approve(constants.arenaContract, ethers.constants.MaxUint256)
            .send({ from: this.currentUser.walletAddress })
            .then( r => {
                this.isLoading = false
                if( r.status )
                {
                    this.hasApproved = true
                    //this.$emit('user-approved')
                }
            } )
        },
  },
  created(){

  },
  mounted(){
    this.dragonId = this.$route.query.id
    this.isOwner = false

    const web3 = new Web3(window.ethereum)
    this.tinyDragonsContract = new web3.eth.Contract(tinyDragonAbi, constants.tinyDragonsContract)
    this.arenaContract = new web3.eth.Contract( arenaAbi, constants.arenaContract )

    this.arenaContract.methods.hasMigrated( this.dragonId ).call().then( r => {
        this.hasMigrated = r
    })

    this.getJSON( '/json/g1/' + this.dragonId + '.json', ( resp ) => {
        this.name = resp.name

        for (let index = 0; index < resp.attributes.length; index++) {
            const element = resp.attributes[index]
            
            if (element.trait_type === 'Rank') {
                this.rank = element.value * 1
            } else if ( element.trait_type === 'Attribute') {
                let rarirtyScore = this.rarityScores[element.value] / 10
                this.attributes.push( { name: element.value, percent: rarirtyScore })
            }
        }
    })

    this.tinyDragonsContract.methods.getStats( this.dragonId ).call().then( r => {
        this.currentLevel = r.level
        this.currentAttack = r.attack
        this.currentDefense = r.defense
    })

    this.arenaContract.methods.getStats( this.dragonId ).call().then( r => {
        if( r.level > 0)
        {
            this.currentLevel = r.level
            this.currentAttack = r.attack
            this.currentDefense = r.defense
        }
    })

    this.arenaContract.methods.levelUpTokens( this.dragonId ).call().then( r => {
        this.freeLevels = r
    })

    // console.log( this.currentUser )

    if( this.currentUser && this.currentUser.walletAddress && this.currentUser.walletAddress !== '' )
    {
        this.tinyDragonsContract.methods.ownerOf( this.dragonId )
            .call()
            .then( r =>{
                if( r.toLowerCase() === this.currentUser.walletAddress.toLowerCase() )
                {
                    this.isOwner = true
                }
            });

        const dcauContract = new web3.eth.Contract(dcauAbi, constants.dcauContract);

        dcauContract.methods.allowance( this.currentUser.walletAddress,constants.arenaContract ).call().then( r => {
            if( r > 0 )
            {
                this.hasApproved = true
            }
        })

        window.setInterval( 
            ()=>{
                this.arenaContract.methods.getLevelUpPriceInNative().call().then( r => {
                    // console.log(r)
                    this.levelUpPriceInNative = new BigNumber (r)

                    dcauContract.methods.balanceOf( this.currentUser.walletAddress ).call().then( r => {
                        this.nativeBalance = new BigNumber( r )
                        if (this.nativeBalance.lt( this.levelUpPriceInNative )) {
                            if( this.freeLevels < 1)
                            {
                                this.isError = true
                                this.errorMessage = "You do not have enough DCAU to level up."
                            }
                        } else {
                            this.handleInput()
                        }
                    })
                })

            
            }, 1000
        )

    }

    window.setInterval( ()=> {
        if( this.currentRankings && this.currentRankings.length > 0 )
        {
            const tinyDragonRating = this.currentRankings.find( e => (e.tokenId*1) == (this.dragonId*1) );

            if (tinyDragonRating) {
                this.hasArenaRank = true;
                this.arenaRank = tinyDragonRating.number;
            }
        }
    }, 1000 )
  }
}
</script>

<style>
    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    {
        .container{
            max-width: 95% !important;
            flex-wrap: wrap;
        }

        .card, .attributes{
            max-width: 100% !important;
        }

        .card + .attributes{
            margin-left: 0 !important;
            margin-top: 10px;
        }
    }

    .button, Button{
        margin: 16px;
        display: inline-block;
        color: #fff;
        border-radius: 6px;
        border: 2px solid #0c7b97;
        font-weight: bold;
        background: rgb(102,193,216);
        padding: 12px 16px;
        background: linear-gradient(180deg, rgba(102,193,216,1) 0%, rgba(73,182,208,1) 50%, rgba(43,170,201,1) 100%);
        box-shadow: rgba(0,0,0,0.5) 0 6px 10px;
        transition: transform 0.2s ease-in-out;
        cursor: pointer;
        text-decoration: none;
    }

    Button:hover, .button:hover{
        transform: scale(105%);
        transition: transform 0.2s ease-in-out;
        background: linear-gradient(180deg, rgba(102,193,216,0.7) 0%, rgba(73,182,208,0.7) 50%, rgba(43,170,201,0.7) 100%);
    }

    .container{
        display: flex;
        max-width: 60%;
        margin: 0 auto;
        padding-bottom: 48px;
    }

    .container ul, .container li{
        list-style: none;
    }

    .smol-logo{
        max-height: 110px;
        margin-bottom: 16px;
    }

    .loading-cloud .element .error{
        text-transform: none;
        color: #ff1111;
        font-size: 12px;
    }

    .loading-cloud.level-confirm .element h3{
        margin-bottom: -6px;
    }

    .loading-cloud.level-confirm .element div{
        padding-top: 48px;
        max-width: 300px;
        font-size: 16px;
    }

     .loading-cloud.level-confirm Button{
         z-index: 100;
         pointer-events: all;
         margin-top: -6px;
     }

      .loading-cloud.level-confirm.hide Button{
          pointer-events: none;
      }

    .loading-cloud.level-confirm .element div p{
        text-transform: none;
        font-size: 15px;
    }

    .card, .attributes{
        border: 2px solid #0c7b97;
        border-radius: 6px;
        background: rgba(255,255,255,0.9);
        overflow: hidden;
        max-width: 50%;
    }

    .loading-cloud.large .element{
        background: url('/img/large-cloud.png') no-repeat center center;
        pointer-events: all;
    }

    .loading-cloud.large .close{
        pointer-events: all;
        z-index: 100;
    }

    .loading-cloud.large.hide .element, .loading-cloud.large.hide .close{
        pointer-events: none;
    }

    .attributes ul{
        padding-left: 12px;
        padding-right: 12px;
        text-align: left;
    }

    .attributes ul .title
    {
        color: #fff;
        background: #0c7b97;
    }

    .level-content{
        width: 262px;
    }

    .level-content h3{
        color: #0c7b97;
        font-size: 20px;
    }

    .level-content .form-item input{
        line-height: 28px;
        font-size: 18px;
    }

    .level-content form label{
        margin-right: 6px;
    }

    .form-item + .form-item{
        margin-left: 6px;
    }

    .level-content form{
        display: flex;
    }

    .level-content form div{
        display: block;
        flex-grow: 1;
        width: 48%;
    }

    .attributes ul li{
        border: 2px solid #0c7b97;
        border-radius: 6px;
        background: rgba(255,255,255,0.9);
        overflow: hidden;
        padding: 12px;
        margin-bottom: 8px;
        display: flex;
    }

    .attributes li div{
        flex-grow: 1;
    }

    .attributes li .percent{
        text-align: right;
        font-weight: bold;
    }

     .card + .attributes{
         margin-left: 2%;
     }

     .card h3{
         margin-top: 4px;
         margin-bottom: 4px;
     }

    .card img{
        max-width: 95%;
    }

    .card ul{
        display: flex;
        flex-wrap: wrap;
        padding-left: 12px;
        padding-right: 12px;
    }

    .card ul li{
        flex-grow: 1;
        width: 50%;
        vertical-align: middle;
        margin-bottom: 4px;
        text-align: left;
        font-weight: bold;
        display: flex;
        align-items: center;
        /* justify-content: center */
    }

    .card ul li .img-box{
        width: 44px;
        text-align: center;
    }

    

    .card ul li  img{
        max-height: 32px;
    }

    .card h2{
        color: #fff;
        background-color: #0c7b97;
        margin: 0 0 12px 0;
        padding: 14px;
        text-align: left;
    }

    .container .card, .container .attributes {
        flex-grow: 1;
    }

    .container Button{
        margin-top: 0;
    }
</style>