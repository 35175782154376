import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import MyDragons from '../views/MyDragons'
import View from '../views/View'
import Lottery from '../views/Lottery'
import ArenaFightReport from '../views/ArenaFightReport'
import Arena from '../views/Arena'
import ArenaMatches from '../views/ArenaMatches'
import ArenaRankings from '../views/ArenaRankings'
import ArenaMenu from '../views/ArenaMenu'

const routes = [
    { 
        path: '/',
        name: 'Home',
        component: Home
    },
    { 
        path: '/my-dragons',
        name: 'MyDragons',
        component: MyDragons
    },
    {
        path: '/lottery',
        name: 'Dragon Fire Lottery',
        component: Lottery
    },
    {
        path: '/view',
        name: 'View',
        component: View
    },
    {
        path: '/arena',
        name: 'Arena',
        component: Arena
    },
    {
        path: '/arena-fight-report',
        name: 'Arena Fight Report',
        component: ArenaFightReport
    },
    {
        path: '/arena-matches',
        name: 'Arena Matches',
        component: ArenaMatches
    },
    {
        path: '/arena-rankings',
        name: 'Arena Rankings',
        component: ArenaRankings
    },
    {
        path: '/arena-menu',
        name: 'Arena Menu',
        component: ArenaMenu
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  })
  
export default router