<template>
        <div v-if="showToastFlag" id="toast" :class="showToastFlag ? 'bring-in-toast' : ''">
            <div class="container-1">
                <img src="https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" alt="info">
            </div>
            <div class="container-2">
                <p>{{this.match.matchId}}. {{this.match.matchName}} is ready</p>
                <div class="match-link" @click="closeToast">
                    <a :href="'/arena-fight-report?matchId=' + this.match.matchId"> VIEW MATCH </a>
                </div>
            </div>
            <button id="close" @click="closeToast">
                &times;
            </button>
        </div>
    
</template>
<script>
export default {
        name: 'Toaster',
        props: {
            match: {},
        },
        data() {
            return{ 
                    showToastFlag: false
                }
            },
        methods:{
            showToast(){
                if(!localStorage.getItem("match"+this.match.matchId)){
                    this.showToastFlag = true
                }
            },
            closeToast(){
                localStorage.setItem("match" + this.match.matchId, this.match.matchId)
                this.showToastFlag = false
            }
        },
        mounted(){
            this.showToast()
        },
        unmounted(){ 
        }
}
</script>
<style scoped>
 *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    border: none;
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

.bring-in-toast{
    visibility: visible;
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
}

body{
    background-color: #f9f9f9;
}

#toast{
    width: 370px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgba(75, 50, 50, 0.05);
    border-left: 8px solid #47d764;
    border-radius: 7px;
    display: grid;
    grid-template-columns: 1.2fr 6fr 0.5fr;
    margin-bottom: 10px;
    transform: translateX(0);
}
.container-1,.container-2{
    align-self: center;
}
.container-1 i{
    font-size: 40px;
    color: #47d764;
}

.container-2 p:first-child{
    color: #101020;
    font-weight: 600;
    font-size: 16px;
}
.container-2 p:last-child{
    font-size: 12px;
    color: #656565;
    font-weight: 400;
}

.match-link{
    padding: 5px;
}

#toast button{
    align-self: flex-start;
    background-color: transparent;
    font-size: 25px;
    line-height: 0;
    color: #656565;
    cursor: pointer;
}
</style>