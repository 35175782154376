import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moralis from './plugins/moralis'
import firebase from './plugins/firebase'

createApp(App)
    .provide('$moralis', Moralis)
    .provide('$firebase', firebase )
    .use(store)
    .use(router)
    .mount('#app')
