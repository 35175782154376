export default{
    // chainId: 43113,
    // rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    // dreggTokenContract: '0xa6fBC8c31022dD6b9186f4270e806d9569301e29',
    // tinyDragonsContract: '0x6F9671De8dEE89Ad18E707b91A3819e681631299',
    // fireLotteryContract: '0x792B3745104b5E370D3AaFe054dcdf4b97da8fe8',
    // arenaContract: '0x4F6e4B1D7056dE29112344fE620e9ac84554550C',
    // arenaStartBlock: 2047645,
    // isFuji: true

    chainId: 43114,
    moralisServerUrl: 'https://ho2fy0ghyvmr.usemoralis.com:2053/server',
    moralisAppId: '1wBvJSDIWVUCzqdArzAnJU4S8xlcMEjEw8i7dWnR',
    rpcUrl: 'https://speedy-nodes-nyc.moralis.io/5a807b69b72b7776a2d9195f/avalanche/mainnet',
    tinyDragonsContract: '0x2144A0eB052DD991Ec8f34b6a46D2d2Eb765C026',
    fireLotteryContract: '0x40A6177cbcBE46691BEc38CBF7B86bfc03D3290d',
    arenaContract: '0x671ebf2693552461975a54614F60AE81DFBBa990',
    dcauContract: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
    royaltiesContract: '0x7fa37EE65ca0fb45836325613bA21d7A154E6960',
    arenaStartBlock: 5780517,
    matchesCreatedTableName: 'CreatedMatches',
    isFuji: false
}