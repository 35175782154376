import Constants from "../consts/constants";
import Moralis from "moralis";

const getMoralis = () => {
    Moralis.start({
        serverUrl: Constants.moralisServerUrl,
        appId: Constants.moralisAppId,
    });

    return Moralis;
}

export default getMoralis();