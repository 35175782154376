<template>
  <div class="background">
    <div class="clouds">
      <div class="content">
        <div class="topnav" v-show="!hidden">
          <router-link to="/"><img class="menu-img" alt="Tiny Dragons" src="./assets/logo.png"></router-link >
          <a href="https://app.nftrade.com/assets/avalanche/0x2144a0eb052dd991ec8f34b6a46d2d2eb765c026" target="_blank">Trade</a>
          <router-link to="/my-dragons" >My Dragons</router-link>
          <router-link to="/lottery" >Lottery</router-link>
          <router-link to="/arena-menu" >Arena</router-link>
          <div class="header">
            <LoggedInStatus @user:connected="connect" @user:disconnected="disconnect" />
          </div>
        </div>     
        <router-view
        :currentPriceInDcau="currentPriceInDcau"
        :currentUser="currentUser"
        :hasApproved="hasApproved"
        :currentAvailable="currentAvailable"
        @user-approved="userApproved"
        @hide-navbar="hideNavBar"
        @show-navbar="showNavBar"
        :sfxEnabled="sfxEnabled"
        :musicEnabled="musicEnabled"
        @sfx-switch="toggleSfx"
        @music-switch="toggleMusic"
        @play-music="playMusic"
        @stop-music="stopBackGroundMusic"
        :userDragons="userDragons"
        :showDragonLoaderIndicator="showDragonLoaderIndicator"
        :selectedDragon="selectedDragon"
        @select-dragon="changeSelectedDragon"
        @refresh-dragon="refreshDragon"
        :matches="matches"
        :currentRankings="currentRankings"
        @arena-music="playArenaMusic"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LoggedInStatus from './components/LoggedInStatus'
import dcauAbi from './abi/erc20.json'
import arenaAbi from './abi/tinyDragonArena.json'
import constants from './consts/constants'
import BigNumber from 'bignumber.js'
import { useStore } from 'vuex'
import { inject } from 'vue'

const backGroundMusic = new Audio('/sounds/music/dragon_background_music.mp3')
const arenaMusic = new Audio('/sounds/music/arena_background_music.mp3')

export default {
  name: 'App',
  components: {
    LoggedInStatus,
  },
  data() {
    return{ 
      currentUser: {},
      hasApproved: Boolean,
      currentAvailable: new Number(0),
      currentPriceInDcau: new BigNumber(0),
      hidden: Boolean,  // hide the main navbar on Arena views, methods: hideNavbar, showNavBar these needs to be called trough emits from the view
      sfxEnabled: Boolean, // sfx sounds flag NOT IMPLEMENTED YET
      musicEnabled: Boolean, // background music flag (stored in localStorage)
      userDragons: [], 
      showDragonLoaderIndicator: Boolean, // for MyDragons view the loading indicator flag
      selectedDragon: {}, // by default userDrgaons[0], but it is updated when user select a new one in the Arena,
      matches: [],
      currentRankings: [],
      playArenaBgMusic: Boolean,
      totalWon: new BigNumber(0),
      moralis: {},
      firebase: {},
      web3: {}
    }
  },
  methods:{
    connect( user ){
      // console.log('connect app')
      this.currentUser = user

      const dcauContract = new this.web3.eth.Contract(dcauAbi, constants.dcauContract);

      dcauContract.methods.allowance( this.currentUser.walletAddress,constants.tinyDragonsContract ).call().then( r => {
        if( r > 0 )
        {
          this.hasApproved = true
        }
      })

      this.grabUserDragons()
    },
    disconnect(){
      this.currentUser = { isLoggedIn: false, walletAddress: '' }
      this.hasApproved = false
    },
    userApproved(){
      this.hasApproved = true
    },
    hideNavBar() {
      this.hidden = true
    },
    showNavBar() {
      this.hidden = false
    },
    toggleSfx(){
      this.sfxEnabled = !this.sfxEnabled
      localStorage.setItem("sfxEnabled", this.sfxEnabled);
    },
    toggleMusic(){
      this.musicEnabled = !this.musicEnabled
      localStorage.setItem("musicEnabled", this.musicEnabled);
      if(this.musicEnabled){
        if(this.playArenaBgMusic){
          this.playArenaMusic()
        }else{
          this.playMusic()
        }
      }else {
        this.stopArenaMusic()
        this.stopBackGroundMusic()
      }
    },
    playMusic(){
      this.stopArenaMusic()
      this.playArenaBgMusic = false
      if(this.musicEnabled){
        backGroundMusic.loop = true
        backGroundMusic.play()
      }
    },
    playArenaMusic(){
      this.stopBackGroundMusic()
      this.playArenaBgMusic = true
      if(this.musicEnabled){
        arenaMusic.loop = true
        arenaMusic.play()
      }
    },
    stopBackGroundMusic(){
      backGroundMusic.pause()
      backGroundMusic.currentTime = 0
    },
    stopArenaMusic(){
      arenaMusic.pause()
      arenaMusic.currentTime = 0
    },
    refreshDragon( )
    {
      this.grabUserDragons()
    },
    changeSelectedDragon(dragon){
      if (!dragon.isMigrated) {
        window.alert("Dragon Needs to be Migrated to DCAU - please head to My Dragons then click on this dragon, underneathe it's portrait you should see a migrate button.")
      }

      this.selectedDragon = dragon

      localStorage.setItem( "selected_dragon_" + this.currentUser.walletAddress, dragon.tokenId + "" )
    },
    calculateWinScore( currentRanking )
      {
        const firstScore = 100 * currentRanking.firstPlaceWins;
        const secondScore = 50 * currentRanking.secondPlaceWins;
        const thirdScore = 25 * currentRanking.thirdPlaceWins;

        return firstScore + secondScore + thirdScore;
      },
    getRankings()
      {
        const arenaContract = new this.web3.eth.Contract(arenaAbi, constants.arenaContract)

        arenaContract.getPastEvents("PrizeWon",
        {                               
            fromBlock: constants.arenaStartBlock,     
            toBlock: 'latest' // You can also specify 'latest'          
        }).then(prizeWonEvents => {
            this.currentRankings = [];

            for (let index = 0; index < prizeWonEvents.length; index++) {
                const prizeWonEvent = prizeWonEvents[index];

                this.totalWon = this.totalWon.plus( prizeWonEvent.returnValues.amount );
                
                let placing = prizeWonEvent.returnValues.placing * 1; 

                let tokenId = prizeWonEvent.returnValues.tokenId;
                let currentRanking = this.currentRankings.find( a => a.tokenId == tokenId ); 

                switch (placing) {
                  case 1:
                    if( currentRanking )
                    {
                      currentRanking.firstPlaceWins++;
                    } else {
                      this.currentRankings.push( { tokenId, firstPlaceWins: 1, secondPlaceWins: 0, thirdPlaceWins: 0 } );
                    }
                    break;
                  case 2:
                    if( currentRanking )
                    {
                      currentRanking.secondPlaceWins++;
                    } else {
                      this.currentRankings.push( { tokenId, firstPlaceWins: 0, secondPlaceWins: 1, thirdPlaceWins: 0 } );
                    }
                    break;
                  case 3:
                    if( currentRanking )
                    {
                      currentRanking.thirdPlaceWins++;
                    } else {
                      this.currentRankings.push( { tokenId, firstPlaceWins: 0, secondPlaceWins: 0, thirdPlaceWins: 1 } );
                    }
                    break;
                  default:
                    break;
                }
            }

            

            for (let ri = 0; ri < this.currentRankings.length; ri++) {
              const element = this.currentRankings[ri];
              element.totalScore = this.calculateWinScore( element );          
            }

            this.currentRankings = this.currentRankings.sort((a, b) => (a.totalScore > b.totalScore ? -1 : 1));

            for (let ni = 0; ni < this.currentRankings.length; ni++) {
              const element = this.currentRankings[ni];
              element.number = ni + 1;
            }

            // console.log( this.currentRankings.slice(0,20) )
            // console.log( this.totalWon.dividedBy( 10**18 ).toFixed() )
        })
      },
    grabUserDragons(){
        this.userDragons = [];

        const db = this.firebase.firestore();

        const dragonOwnerTableRef = db.collection('tinyDragonOwners');

        const arenaContract = new this.web3.eth.Contract(arenaAbi, constants.arenaContract)

        dragonOwnerTableRef.where( "owner", "==", this.currentUser.walletAddress.toLowerCase() ).get().then( query => {
            if( query.docs.length > 0 )
            {
              query.docs.forEach(doc => {
                const dragonId = doc.data().dragonId
                arenaContract.methods.getStats( dragonId ).call().then( r => {
                  this.userDragons.push( {
                        tokenId: dragonId,
                        currentLevel: r.level,
                        currentAttack: r.attack,
                        currentDefense: r.defense,
                        isMigrated: (r.level * 1) !== 0 
                      })
                  })
                  this.showDragonLoaderIndicator = false
              })
            } else {
              this.showDragonLoaderIndicator = false
            }
        })
    },
    grabArenaMatches(){
      const arenaContract = new this.web3.eth.Contract(arenaAbi, constants.arenaContract)

      const matches = this.moralis.Object.extend(constants.matchesCreatedTableName)
      const query = new this.moralis.Query(matches)

      query.descending('block_timestamp').limit(150)

      query.find().then( results => {
        let resultsDone = 0;
        results.forEach(result => {
          const matchId = result.attributes.matchId
          try {
            arenaContract.methods.matchInfo(matchId).call().then(matchInfo => {
              const currentMatchIndex = this.matches.findIndex(match => match.matchId === matchId)

              if( currentMatchIndex != -1 )
              {
                this.matches[currentMatchIndex] = matchInfo
              } else {
                this.matches[this.matches.length] = matchInfo
              }

              resultsDone++;

              if( resultsDone === results.length )
              {
                setTimeout(() => {
                  this.grabArenaMatches()
                }, 5000);
              }
            })
          } catch (error) {
            console.log(error)
          }
        })
      })
    }
  },
  watch: {
    userDragons: {
        deep: true,
        async handler(){
          if( this.userDragons.length > 0 )
          {
            let currentDragonIds = []

            if( this.userDragons.length > 0 )
            {
              this.userDragons.forEach( ud => {
                currentDragonIds.push( ud.tokenId )
              })
            }

            let currentSelectedDragonId = localStorage.getItem("selected_dragon_" + this.currentUser.walletAddress)

            if( currentSelectedDragonId && currentDragonIds.indexOf( currentSelectedDragonId*1 ) >= 0 )
            {
              const selectedDragonId = localStorage.getItem("selected_dragon_" + this.currentUser.walletAddress) * 1;
              for (let index = 0; index < this.userDragons.length; index++) {
                const element = this.userDragons[index];

                if (element.tokenId == selectedDragonId) {
                  this.selectedDragon = element;

                  if (!this.selectedDragon.isMigrated) {
                    window.alert("Dragon Needs to be Migrated to DCAU - please head to My Dragons then click on this dragon, underneathe it's portrait you should see a migrate button.")
                  }
                }
              }
            } else {
              this.selectedDragon = this.userDragons[0]
              localStorage.setItem( "selected_dragon_" + this.currentUser.walletAddress, this.userDragons[0].tokenId + "" )

              if (!this.selectedDragon.isMigrated) {
                window.alert("Dragon Needs to be Migrated to DCAU - please head to My Dragons then click on this dragon, underneathe it's portrait you should see a migrate button.")
              }
            }
          }
        }
    }
},
  created(){
    this.currentUser = { isLoggedIn: false, walletAddress: '', userDragons: [] }
    this.selectedDragon = {}
    this.hasApproved = false
    this.hidden = false
    this.showDragonLoaderIndicator = true
    this.sfxEnabled = (localStorage.getItem("sfxEnabled") === 'true')
    if (this.sfxEnabled === null){
        this.sfxEnabled = true
    }
    localStorage.setItem("sfxEnabled", this.sfxEnabled);
    
    this.musicEnabled =  (localStorage.getItem("musicEnabled") === 'true')
    if (this.musicEnabled === null){
        this.musicEnabled = true
    }
    localStorage.setItem("musicEnabled", this.musicEnabled);
    this.playArenaBgMusic = false
  },
  mounted(){
    const store = useStore()
    const $moralis = inject('$moralis')
    const $firebase = inject('$firebase')

    this.moralis = $moralis
    this.firebase = $firebase

    this.moralis.Web3.enableWeb3().then( mWeb3 => {
      this.web3 = mWeb3

      const user = $moralis.User.current()

      if (user) {
        store.commit('setUser', user)
      }

      this.grabArenaMatches()

      this.getRankings()

      window.setInterval( ()=>{ this.getRankings() }, 15000 )
    })
  },
  beforeUnmount(){
    clearInterval()
  }
}
</script>

<style>
.menu-img{
  width: 140px
}

.topnav {
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

/* Style the links inside the navigation bar */
.topnav a {
  color: rgb(66,179,207);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  font-family: 'KG Happy';
  transition: transform 0.2s ease-in-out;
}

.topnav a:hover {
  transform: scale(110%);
  transition: transform 0.2s ease-in-out;
}


@font-face {
    font-family: 'Komika Axis';
    src: url('/fonts/KOMIKAX_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KG Happy';
    src: url('/fonts/kghappy-webfont.woff2') format('woff2'),
         url('/fonts/kghappy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Godzilla';
    src: url('/fonts/godzilla-webfont.woff2') format('woff2'),
         url('/fonts/godzilla-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
{
  .tg1, .tg2{
    background-size: 30% !important;
  }

  .header{
    text-align: center;
    margin-bottom: 16px;
  }
}

h1{
  margin-bottom: 0;
}


.warning{
  background: red;
  color: white;
  margin-top: 0px;
  padding: 8px;
}

body, #app, html, .tg1, .tg2{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.tg1{
  position: fixed;
  background: url('/img/tg1.png') no-repeat bottom left;
}

.tg2{
  position: fixed;
  background: url('/img/tg2.png') no-repeat bottom right;
}

.tg1,.tg2{
  bottom: 12px;
  z-index: 10;
}

.content{
  z-index: 12;
  position: relative;
  width: 100%;
  height: 100%;
}

.content .page-heading{
  font-family: 'Komika Axis';
  color: #fff;
  font-size: 32px;
  font-weight: normal;
  text-shadow: 2px 0 1px #42b3cf, 0 -2px 1px #42b3cf, 0 2px 1px #42b3cf, -2px 0 1px #42b3cf;
}


#app {
  font-family: century-gothic, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(157,210,255);
  background: linear-gradient(180deg, rgba(157,210,255,1) 0%, rgba(255,255,255,1) 90%);
  width: 100%;
  height: 100%;
}

.background{
  width: 100%;
  height: 100%;
}

.clouds{
  width: 100%;
  height: 100%;
  background: url('/img/clouds.png') repeat-x top left;
  animation: scroll 36s infinite cubic-bezier(0.300, 0.465, 0.770, 0.580);;
}

img{
  max-width: 90%;
}

@keyframes scroll {
  0%{
    background-position-x: 0;
  }
  100%{
    background-position-x: 1200px;
  }
}

.bounce{
  animation: bounce 0.75s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}
.loading-cloud{
  position: fixed;
  z-index: 14;
  background: rgba(157,210,255,0.75);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}


.loading-cloud .close, .info{
  padding: 8px 12px;
  border: 3px solid;
  border-radius: 12px;
  background: rgba(255,255,255,0.5);
}
.loading-cloud .close{
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.loading-cloud .element{
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/img/single-cloud.png') no-repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-cloud .element p{
  text-transform: uppercase;
  font-size: 20px;
}
.loading-cloud.hide{
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@keyframes bounce {
    from {
      
        transform: translate3d(0, 0, 0);
    }
    to {
      
        transform: translate3d(0, -22px, 0);
    }
}

.hide-items {
  position: absolute;
  left: -999px;
}

</style>
