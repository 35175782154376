<template>
    <div class="match-holder">
        <div class="wrapper">
            <img src="/img/match-item-bg.png" alt="Match Item">
            <div class="stars-lvl">
                <div class="level-text">
                    {{this.matchName}} LVL {{match.minLevel}}-{{match.maxLevel}}
                </div>
            </div>
            <div class="entered">
                <div class="loading-indicator">
                    <div v-bind:class="numberOfYellowBars >= 1 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 2 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 3 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 4 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 5 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 6 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 7 ? 'yellow' : '' " class="square"></div>
                    <div v-bind:class="numberOfYellowBars >= 8 ? 'yellow' : '' " class="square"></div>
                </div>
                <div class="entered-text">
                    {{match.currentEntriesCount}}/{{match.playerCount}} Tiny Dragon's Entered
                </div>
            </div>
            <div class="entry-prize">
                <img class="token-img" :src="imgUrl" :alt="tokenSymbol">
                <div v-if="hasApproved" class="entry-prize-text">
                    <div>
                        Entry: {{tokenTotal}} {{tokenSymbol}}
                    </div>
                    <div>
                        Prize Pool: {{tokenPrize}} {{tokenSymbol}}
                    </div>
                </div>
                <div @click="approveUser" class="approve-holder" v-else>
                    <img class="approve-img" alt="Approve Button" src="/img/arena-button.png">
                    <div class="approve-text">Approve {{tokenSymbol}}</div>
                </div>
            </div>
            <div class="buttons" v-if="this.isActive">
                <div v-if="this.isEntryAllowed" class="enter active" @click="enterMatch">  
                    <img class="button-img" alt="Enter Button" src="/img/arena-button.png">
                    <div class="enter-text">ENTER</div>
                </div>
                <div v-else class="enter">  
                    <img class="button-img" alt="Enter Button" src="/img/arena-button.png">
                    <div class="enter-text">ENTER</div>
                </div>
            </div>
            <div class="buttons" v-else-if="this.isViewReady">
                <router-link class="view-results" :to="'/arena-fight-report?matchId=' + this.match.matchId">  
                    <img class="button-img" alt="View Button" src="/img/arena-button.png">
                    <div class="view-results-text">VIEW RESULTS</div>
                </router-link>
            </div>

            <div @click="toggleModal" class="stone-text-holder">
                <img class="long-stone" alt="long stone" src="/img/long-button.png">
                <div class="opponents-text">View Opponents</div>
            </div>
        </div> 
        <DragonModal :showClose="true" @close="toggleModal" :modalActive="modalActive">
            <div class="modal-content">
                <div class="modal-stone-text-holder">
                    <img class="modal-stone" alt="Arena Banner" src="/img/long-button.png">
                    <div class="modal-text">MATCH OPPONENTS</div>
                </div>
                <div class="selectable-dragons">
                    <UserDragon v-for="item in this.opponents" :key="item.tokenId"
                    :dragon="item"
                    />
                </div>
            </div>
        </DragonModal>
    </div>
</template>
<script>
import erc20Abi from '../abi/erc20.json'
import arenaAbi from '../abi/tinyDragonArena.json'
import tinyDragonAbi from '../abi/tinyDragon.json'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import constants from '../consts/constants'
import { ethers } from 'ethers'
import UserDragon from '../components//UserDragon'
import DragonModal from '../components/DragonModal'
import { ref } from "vue";


export default {
        name: 'MatchItem',
        components: {
            UserDragon,
            DragonModal,
        },
        props: {
            currentUser:{},
            match:{},
            selectedDragon: {},
            showActive: Boolean,
            showEntered: Boolean,
            userDragons: {},
        },
        setup() {
            const modalActive = ref(false);
            const toggleModal = () => {
            modalActive.value = !modalActive.value;
            };
            return { modalActive, toggleModal };
        },
        data() {
            return{
                tokenAmount : BigNumber,
                tokenTotal: Number,
                tokenSymbol: String,
                tokenPrize: Number,
                imgUrl: String,
                numberOfYellowBars: Number,
                hasApproved: Boolean,
                isEntryAllowed: Boolean,
                isMatchReady: Boolean,
                opponents: [],
                isActive: Boolean,
                isEntered: Boolean,
                isViewReady: Boolean,
                matchName: String
            }
        },
        methods:{
            enterMatch(){
                this.showLoading = true
                this.arenaContract.methods.enterMatch( this.selectedDragon.tokenId, this.match.matchId )
                    .send({ from: this.currentUser.walletAddress }).then( r => {
                        this.showLoading = false

                        if( r.status )
                        {
                            this.isEntryAllowed = false
                        }
                })
            },
            convertEntryAndPrize(){
                this.erc20Contract.methods.decimals().call().then( r=> {
                    this.tokenTotal = new BigNumber(this.match.tokenAmount).div( 10 ** r ).times( 1.1 )
                    this.tokenPrize = new BigNumber(this.match.tokenPrizePoolAmount).div(10 ** r)
                })
            },
            grabSymbolAndSetImgUrl(){
                this.erc20Contract.methods.symbol().call().then( r => {
                    this.tokenSymbol = r
                    this.imgUrl = 'https://aurum.dragoncrypto.io/assets/images/tokens/' + this.tokenSymbol.toLowerCase() + '.png'
                })
            },
            setLoadingIndicator(){
                this.numberOfYellowBars = Math.round(this.match.currentEntriesCount * (8 / this.match.playerCount))
            },
            isTokenApproved(){
                this.erc20Contract.methods.allowance( this.currentUser.walletAddress, constants.arenaContract ).call().then( r => {
                if( r > 0 )
                {
                    this.hasApproved = true
                }
            })
            },
            checkIsEntryAllowed(){
                this.isEntryAllowed = 
                    (this.match.currentEntriesCount * 1) < (this.match.playerCount * 1)
                    && !this.match.matchTokenIds.includes(this.selectedDragon.tokenId + "")
            },
            checkIsMatchReady(){
                this.isMatchReady = this.match.isFinished
            },
            approveUser(){
                this.erc20Contract.methods.approve(constants.arenaContract, ethers.constants.MaxUint256)
                .send({ from: this.currentUser.walletAddress })
                .then( r => {
                    this.showLoading = false
                    if( r.status )
                    {
                        this.hasApproved = true
                    }
                } )
            },
            grabOpponents(){
                this.match.matchTokenIds.forEach(tokenId => {
                    this.arenaContract.methods.getStats( tokenId ).call().then( r => {
                        const currentDragonIndex = this.opponents.findIndex(dragon => dragon.tokenId === tokenId)
                        const dragon = {
                            tokenId: tokenId,
                            currentLevel: r.level,
                            currentAttack: r.attack,
                            currentDefense: r.defense
                        }

                        if( currentDragonIndex != -1 ){
                            this.opponents[currentDragonIndex] = dragon
                        } else {
                            this.opponents[this.opponents.length] = dragon
                        }
                    })
                });
            },
            isMatchActive(){
                this.arenaContract.methods.isMatchActive(this.match.matchId).call().then(r => {
                    this.isActive = r
                })
            },
            isUserEntered(){
                this.userDragons.forEach(dragon => {
                    const tokenIdString = dragon.tokenId + ""

                    this.match.matchTokenIds.forEach( m => {
                        if( m === tokenIdString)
                        {
                            this.isEntered = true
                        }
                    })
                })
            },
            isMatchViewReady(){
                this.isViewReady = !this.match.isMatchWaitingToComplete; 
                if(this.isMatchReady && this.isEntered){
                    this.$emit("show-toast", this.match)
                }
            },
            setMatchName(){
                this.matchName = this.match.matchName;
            }
        },
        created(){
            const web3 = new Web3(window.ethereum)
            this.erc20Contract = new web3.eth.Contract(erc20Abi, this.match.token)
            this.hasApproved = false
            this.arenaContract = new web3.eth.Contract(arenaAbi, constants.arenaContract )
            this.tinyDragonsContract = new web3.eth.Contract(tinyDragonAbi, constants.tinyDragonsContract)
            this.isEntered = false
        },
        mounted(){
            this.convertEntryAndPrize()
            this.grabSymbolAndSetImgUrl()
            this.setLoadingIndicator()
            this.isTokenApproved()
            this.checkIsEntryAllowed()
            this.checkIsMatchReady()
            this.grabOpponents()
            this.isMatchActive()
            this.isUserEntered()
            this.isMatchViewReady()
            this.setMatchName()
        },
        watch: {
            match: {
                deep: true,
                handler(){
                    this.grabOpponents()
                    this.convertEntryAndPrize()
                    this.setLoadingIndicator()
                    this.checkIsMatchReady()
                    this.checkIsEntryAllowed()
                    this.isUserEntered()
                    this.isMatchViewReady()
                    this.setMatchName()
                }
            }
        }
}
</script>
<style scoped>

.match-holder{
    width: 30%;
    color: rgba(254, 199, 61, 0.988);   
    
}

.wrapper{
    position: relative;
    width: 100%;
    height: 100%;
}

.stars-lvl {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    position:absolute;
    width: 100%;
    top: 18px;
}

.stars{
    width: 20%;
    padding-right: 10px;
}

.level-text {
    margin-top: 1.5%;
    font-size: 1.3vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.entered{
    display: flex;
    position: absolute;
    width: 100%;
    top: 17%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.loading-indicator{
    display: flex;
}

.square {
    height: 25px;
    width: 10px;
    background-color: #555;
    border-radius: 3px;
    margin-right: 2px;
}

.yellow {
    background-color: rgba(254, 199, 61, 0.988);
}

.entered-text{
    font-family: fantasy;
    font-size: 1.1vw;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.entry-prize{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 26%;
    width: 75%;
    left: 12%;
    padding: 12px 0;
    background-color: rgb(95, 95, 95);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    border-radius: 10px;
}

.entry-prize-text{
    font-size: 1.0vw;
}

.token-img{
    width: 20%;
}

.buttons{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    position:absolute;
    top: 52%;
    width: 100%;
}

.enter, .start{
    width: 33%;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.enter:hover, .start:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

.enter-text {
    font-size: 2.5vw;
    font-family: 'Godzilla';
    /* color: rgba(254, 199, 61, 0.988); */
    color: rgb(95, 95, 95);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position:relative;
    width: 80%;
    transform: translate(14%, -130%);
}

.active .enter-text{
    color: rgba(254, 199, 61, 0.988);
}

.active .start-text{
    color: rgba(255, 183, 0, 0.988);
}
    
.start-text {
    font-size: 2.5vw;
    font-family: 'Godzilla';
    /* color: rgb(255, 183, 0); */
    color: rgb(95, 95, 95);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position:relative;
    width: 80%;
    transform: translate(14%, -140%);
}


.approve-holder{
    position: relative;
    transition: transform 0.2s ease-in-out;
    width: 50%;
    cursor: pointer;
}

.approve-holder:hover{
    transform: scale(105%);
    transition: transform 0.2s ease-in-out;
}

.approve-text {
    font-size: 1.5vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position:absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}


.stone-text-holder{
    position: relative;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.stone-text-holder:hover{
    transform: scale(105%);
    transition: transform 0.2s ease-in-out;
}

.opponents-text {
    font-size: 2.5vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position:absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.view-results {
    width: 35%;
    position: relative;
    top: -3px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.view-results:hover{
    transform: scale(105%);
    transition: transform 0.2s ease-in-out;
}

.view-results-text{
    font-size: 1.5vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position:absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}
    
</style>