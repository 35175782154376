<template>
    <div class="account-details">
        <div v-if="currentUser.isLoggedIn">
            <div>
                <Button :text="currentUser.walletAddress.substr(0,6) + '...' 
                    + currentUser.walletAddress.substr(currentUser.walletAddress.length - 4,currentUser.walletAddress.length) + ' Log Out'" 
                    :enabled="true" @btn-click="logout" />
            </div>
        </div>
        <div v-else>
            <Button :text="'Connect'" @btn-click="login" :color="'primary'" :enabled="true" />
        </div>
    </div>
</template>
<script>
    import Button from './Button'
    import constants from '../consts/constants'
    import { useStore } from 'vuex'
    import { inject } from 'vue'

    export default{
        name: 'LoggedInStatus',
        props: {
          
        },
        data() {
            return {
                store: {},
                moralis: {},
                web3: {},
                currentUser: {
                    isLoggedIn: false,
                    walletAddress: ''
                }
            }
        },
        components: {
            Button
        },
        methods:{            
            connect( account )
            {
                this.currentUser.walletAddress = account
                this.currentUser.isLoggedIn = true
                this.$emit('user:connected', this.currentUser)
            },

            disconnect()
            {
                this.currentUser.walletAddress = ''
                this.currentUser.isLoggedIn = false
                this.$emit('user:disconnected')
            },
            async logout(){
                await this.moralis.User.logOut()
                this.store.commit('setUser', {})

                this.disconnect()
            },
            async login(){
                try{
                    const user = await this.moralis.Web3.authenticate({
                        signingMessage:"Please sign this transaction so our system can verfiy you own this wallet address."  
                    })

                    this.store.commit('setUser', user)

                    this.connect( user.get('ethAddress') )
                } catch (error)
                {
                    console.log(error);
                }
            }
        },
        mounted(){
            this.store = useStore()
            this.moralis = inject('$moralis')

            this.moralis.Web3.enableWeb3().then( mWeb3 => {
                this.web3 = mWeb3
            
                this.web3.eth.getChainId().then( chainId => {
                    if( chainId !== constants.chainId )
                    {
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: '0x' + constants.chainId.toString(16) }], // chainId must be in hexadecimal numbers
                        }).then( ()=>{
                            this.web3.eth.getAccounts((error,result) => {
                                if ( result.length > 0) 
                                {
                                    const user = this.moralis.User.current()
                                    if (user) {
                                        this.connect( user.get('ethAddress'))
                                    }
                                }
                            });
                        })

                        return;
                    }

                    const user = this.moralis.User.current()
                    if (user) {
                        this.connect( user.get('ethAddress'))
                    }
                
                })
            })
        }
    }
</script>
<style scoped>

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    {
        .account-details{
            text-align: center !important;
        }
    }

    .account-details{
        text-align: right;
    }

    .button, Button{
        margin: 16px;
        display: inline-block;
        color: #fff;
        border-radius: 6px;
        border: 2px solid #0c7b97;
        font-weight: bold;
        background: rgb(102,193,216);
        padding: 12px 16px;
        background: linear-gradient(180deg, rgba(102,193,216,1) 0%, rgba(73,182,208,1) 50%, rgba(43,170,201,1) 100%);
        box-shadow: rgba(0,0,0,0.5) 0 6px 10px;
        transition: transform 0.2s ease-in-out;
        cursor: pointer;
    }

    Button:hover, .button:hover{
        transform: scale(105%);
        transition: transform 0.2s ease-in-out;
        background: linear-gradient(180deg, rgba(102,193,216,0.7) 0%, rgba(73,182,208,0.7) 50%, rgba(43,170,201,0.7) 100%);
    }

    .button p{
        padding: 0;
        margin: 0;
    }

    .account-details div{
        display: inline-block;
    }

    .wallet-address{
        font-weight: bold;
        margin: 0 8px 0 12px;
    }
</style>