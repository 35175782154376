<template>
    <div>
          <div class="arena-content">
            <ArenaNavBar 
            @hide-navbar="$emit('hide-navbar')"
            @show-navbar="$emit('show-navbar')" 
            :currentUser="this.currentUser"
            :userDragons="userDragons"
            :selectedDragon="selectedDragon"
            @select-dragon="changeSelectedDragon"
            />
            <div class="rankings" v-if="currentRankings.length > 0">
              <ul>
                <li class="rank-item" v-for="item in this.currentRankings.slice(0,20)" :key="item.tokenId">
                  <div class="number">
                    <h2>{{item.number}}</h2>
                  </div>
                  <div class="img">
                    <a :href="'view?id=' + item.tokenId">
                      <img :src="'/dr/g1/' + item.tokenId + '.png'" :alt="item.tokenId" />
                    </a>
                  </div>
                  <div class="wins">
                    <p>1st Place Wins: {{ item.firstPlaceWins }}</p>
                    <p>2nd Place Wins: {{ item.secondPlaceWins }}</p>
                    <p>3rd Place Wins: {{ item.thirdPlaceWins }}</p>
                  </div>
                </li>
              </ul>
            </div>
        </div>
        <ArenaScene 
        :distanceBackground="mountainsLower"
        :trees="hideItems"
        :ground="groundLower"
        :sfxEnabled="sfxEnabled"
        :musicEnabled="musicEnabled"
        @sfx-switch="$emit('sfx-switch')"
        @music-switch="$emit('music-switch')"
         />
    </div>
</template>
<script>

import ArenaScene from '../components/ArenaScene'
import ArenaNavBar from '../components/ArenaNavBar'
import Web3 from 'web3'
import tinyDragonAbi from '../abi/tinyDragon.json'
import constants from '../consts/constants'
import arenaAbi from '../abi/tinyDragonArena.json'

export default {
  name: 'ArenaRankings',
  props: {
    currentUser: {},
    sfxEnabled: Boolean,
    musicEnabled: Boolean,
    userDragons: Array,
    currentRankings: Array,
    selectedDragon: {},
  },
  data() {
    return{ 
      hideItems: "hide-items",
      mountainsLower: "mountains-lower",
      groundLower: "ground-lower",
      arenaContract: {},
      tinyDragonsContract: {},
      web3: {}
    }
  },
  methods:{
      changeSelectedDragon(dragon){
          this.$emit('select-dragon', dragon)
      }
  },
  components: {
      ArenaScene,
      ArenaNavBar
  },
  created(){
    this.web3 = new Web3(window.ethereum)
    this.tinyDragonsContract = new this.web3.eth.Contract(tinyDragonAbi, constants.tinyDragonsContract)
    this.arenaContract = new this.web3.eth.Contract(arenaAbi, constants.arenaContract)
  },
  mounted(){
        this.$emit("play-music")
  }
}
</script>
<style scoped>
  .arena-content{
      display: flex;
      position: absolute;
      z-index: 10;
      flex-direction: column;
      align-items: center;
  }

  .rankings{
    max-height: 60vh;
    overflow-y: scroll;
    width: 60vw;
  }

  ul, li{
    list-style: none;
  }

  .rank-item{
    background: rgba(255,255,255,0.8);
    border-radius: 4px;
    display: flex;
    margin: 1%;
    padding: 1%;
  }

  .rank-item:first-child{
    background: rgba(255, 255, 153,0.8);
  }

  .rank-item:nth-child(2){
    background: rgba(194, 194, 194,0.8);
  }

  .rank-item:nth-child(3){
    background: rgba(240, 196, 152,0.8);
  }

  .rank-item .number{
    width: 10%;
    
  }



  .number h2{
    font-family: 'Godzilla', sans-serif;
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    font-size: 38px;
    margin: 22% 0 0 0;
    font-weight: normal;
  }

  .rank-item .img{
    width: 10%;
  }

  .rank-item .img img{
    border-radius: 6px;
    border: 1px solid #000;
  }

  .rank-item div+div{
    margin-right: 1%;
  }

  .rank-item .wins{
    display: flex;
    width: 77%;
    margin-top: 1%;
  }

  .rank-item .wins p{
    width: 32%;
    font-weight: bold;
    font-family: 'Komika Axis', sans-serif;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  }
</style>