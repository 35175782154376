<template>
  <h2 class="page-heading">My Tiny Dragons</h2>
  <div v-if="showRoyalties" class="royalties">
      <h4>Your sale royalties: </h4>
      <h4>{{this.totalRoyalties.div( 10**18 ).toFixed(6)}} WAVAX</h4>
      <Button class="button" :enabled="this.canClaim" :text="'Claim'" @btn-click="claimRoyalties"></Button>
    </div>
  <div class="container">
    <div class="dragon" v-for="item in this.userDragons" :key="item.tokenId">
      <div class="image">
        <router-link class="img-link" :to="'/view?id=' + item.tokenId">
            <img :src="'/dr/g1/' + item.tokenId + '.png'" alt="Tiny Dragon" />
        </router-link>  
      </div>
      <div class="text">
       <router-link class="text-link" :to="'/view?id=' + item.tokenId">
          Tiny Dragon #{{item.tokenId}}
        </router-link>
      </div>
    </div>
  </div>
  <div :class="this.showLoading ? 'loading-cloud' : 'loading-cloud hide'">
    <div class="close" @click="hideLoader()">Close</div>
    <div class="element bounce">
      <p>Processing...</p>
    </div>
  </div>
</template>

<script>
//import dreggAbi from './abi/erc20.json'

import royaltiesAbi from '../abi/royalties.json'
import Constants from '../consts/constants'
import { inject } from 'vue'
import Button from '../components/Button.vue';
import BigNumber from 'bignumber.js'

export default {
  components: { Button },
  name: 'MyDragons',
  props: {
    currentUser: {},
    userDragons: Array,
    showDragonLoaderIndicator: Boolean
  },
  data() {
    return {
      showLoading: false,
      royaltiesContract: {},
      totalRoyalties: new BigNumber(0),
      showRoyalties: false,
      canClaim: false,
      moralis: {}
    }
  },
  methods:{
    getDragonIds(){
      let dragonIds = []

      this.userDragons.forEach( d => {
        dragonIds.push(d.tokenId)
      })

      return dragonIds
    },
    hideLoader(){
       this.showLoading = false
    },
    async claimRoyalties(){
      await this.royaltiesContract.methods.claimCommunityBatch( this.getDragonIds() )
        .send( { from: this.currentUser.walletAddress } )
    },
    async setupRoyaltyDisplay(){
      setTimeout( async () => {
        this.totalRoyalties = new BigNumber( await this.royaltiesContract.methods.getTokensBalance( this.getDragonIds() ).call() )

        this.canClaim = this.totalRoyalties.gt( 0 )
        this.showRoyalties = true

        setTimeout( async () => { this.setupRoyaltyDisplay() }, 10000 )
      }, 500)
    }
  },
  async mounted(){
    this.moralis = inject('$moralis')
    this.showLoading = true

    const web3 = await this.moralis.Web3.enableWeb3()
    this.royaltiesContract = new web3.eth.Contract( royaltiesAbi, Constants.royaltiesContract )

    const interval = window.setInterval( () => {
      if (this.userDragons.length > 0) {
        this.showLoading = false

        this.setupRoyaltyDisplay()

        window.clearInterval(interval)
      }
    }, 500 )
  }
};
</script>
<style scoped>
  .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 60%;
  }

  .container .dragon{
    flex-grow: 1;
    border: 2px solid #0c7b97;
    border-radius: 6px;
    background: rgba(255,255,255,0.9);
    overflow: hidden;
    width: 21%;
    margin-bottom: 12px;
  }

  .dragon + .dragon{
    margin-left: 2%;
  }

  .dragon .text{
    padding: 8px 8px 12px 8px;
  }

  .container .dragon img{
    max-width: 100%;
  }

  .container .dragon a{
    color: #0c7b97;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    display: inline-block;
  }

  .container .dragon a:hover{
    transform: scale(110%);
    transition: all 0.2s ease-in-out;
    color: rgb(102,193,216);
  }

  .royalties{
    margin: 16px auto;
    background: rgb(66,179,207);
    display: flex;
    align-content: center;
    justify-content: center;
    max-width: 60%;
    color: #fff;
    font-family: 'Komika Axis';
    border-radius: 8px;
  }

  .royalties h4:first-child
  {
    padding-left: 24px;
    text-align: left;
  }

  .royalties Button{
    background: #fff;
    color: #0c7b97;
    flex-shrink: 1;
  }

  .royalties Button:disabled{
    background: silver;
    color: #fff;
  }

  .royalties h4{
    flex-grow: 1;
  }

  .royalties h4:nth-child(2){
    flex-shrink: 1;
  }
  
</style>