<template>
    <div>
        <div class="arena-content">
            <ArenaNavBar 
            @hide-navbar="$emit('hide-navbar')"
            @show-navbar="$emit('show-navbar')" 
            :currentUser="this.currentUser"
            :userDragons="userDragons"
            :selectedDragon="selectedDragon"
            @select-dragon="changeSelectedDragon"
            />
            <div class="menu-button matches-button">
                <router-link to="/arena-matches">
                    <img class="center-arena-button" alt="Arena Button" src="/img/arena-button.png">
                    <div class="matches-text">MATCHES</div>
                </router-link>
            </div>
             <div class="menu-button">
                <router-link to="/arena-rankings">
                    <img class="center-arena-button" alt="Arena Button" src="/img/arena-button.png">
                    <div class="matches-text">RANKING</div>
                </router-link>
            </div>
        </div>
        <ArenaScene 
        :distanceBackground="mountainsLower"
        :trees="hideItems"
        :ground="groundLower"
        :sfxEnabled="sfxEnabled"
        :musicEnabled="musicEnabled"
        :backButtonClass="hideItems"
        @sfx-switch="$emit('sfx-switch')"
        @music-switch="$emit('music-switch')"
         />
    </div>
</template>
<script>

import ArenaScene from '../components/ArenaScene'
import ArenaNavBar from '../components/ArenaNavBar'

export default {
  name: 'ArenaMenu',
  props: {
    currentUser: {},
    sfxEnabled: Boolean,
    musicEnabled: Boolean,
    userDragons: Array,
    selectedDragon: {}
  },
  data() {
    return{ 
      hideItems: "hide-items",
      mountainsLower: "mountains-lower",
      groundLower: "ground-lower"
    }
  },
  methods:{
      changeSelectedDragon(dragon){
          this.$emit('select-dragon', dragon)
      }
  },
  components: {
      ArenaScene,
      ArenaNavBar
  },
  mounted(){
        this.$emit("play-music")
  }
}
</script>

<style>
.arena-content{
    display: flex;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    align-items: center;
}

.menu-button.matches-button{
    padding-top: 290px;
}

.menu-button {
    padding-left: 10px;
    width: 15%;
    position: relative;
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.menu-button:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

.center-arena-button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.matches-text{
    padding-left: 10px;
    font-family: "Godzilla";
    font-size: 3.3vw;
    color: rgba(254, 199, 61, 0.988);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -42%);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}
</style>