<template>
    <div>
        <div class="arena-nav">
            <div class='tiny-dragons-logo'>
                <router-link @click="showNavBar" to="/"><img class="nav-img" alt="Tiny Dragons" src="../assets/logo.png"></router-link>
            </div>
            <div class='arena-banner' >
                <router-link to="/arena-menu" >
                    <img class="nav-img" alt="Arena Banner" src="/img/arena-logo.png">
                </router-link>
            </div>
            <UserDragon
            :dragon="selectedDragon"
            @toggle-modal="toggleModal"
            />
        </div>
    </div>
    <DragonModal :showClose="true" @close="toggleModal" :modalActive="modalActive">
            <div class="modal-content">
                <div class="modal-stone-text-holder">
                    <img class="modal-stone" alt="Arena Banner" src="/img/long-button.png">
                    <div class="modal-text">SELECT YOUR TINY DRAGON</div>
                </div>
                <div class="selectable-dragons">
                    <UserDragon v-for="item in this.userDragons" :key="item.tokenId"
                    :dragon="item"
                    @select-dragon="changeSelectedDragon"
                    @toggle-modal="toggleModal"
                    />
                </div>
            </div>
    </DragonModal>
</template>
<script>

import UserDragon from '../components//UserDragon'
import DragonModal from '../components/DragonModal'
import { ref } from "vue";

export default {
  name: 'ArenaNavBar',
  inheritAttrs: false,
  props: {
    currentUser: {},
    userDragons: Array,
    selectedDragon: {}
  },
  components: {
      UserDragon,
      DragonModal
  },
   setup() {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    return { modalActive, toggleModal };
  },
  data(){
    return {
    }
  },
  methods: {
      hideNavBar(){
          this.$emit('hide-navbar')
      },
      showNavBar(){
          this.$emit('show-navbar')
      },
      changeSelectedDragon(dragon){
          this.$emit('select-dragon', dragon)
      }
  },
  created(){
  },
  mounted(){
    this.hideNavBar()
  }
}
</script>

<style>
.arena-nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 30px;
}


.tiny-dragons-logo{
    width: 15%; 
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.tiny-dragons-logo:hover{
    transform: scale(110%);
    transition: transform 0.2s ease-in-out;
}

.arena-banner{
    padding-right: 20px;
    width: 30%;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.arena-banner:hover{
    transform: scale(105%);
    transition: transform 0.2s ease-in-out;
}

.modal-content {
    display: flex;
    flex-direction: column;
}
.modal-stone-text-holder{
    position: relative;
}

.modal-stone{
    width: 35%;
    padding-bottom: 25px;
}

.modal-text {
    font-size: 2.5vw;
    font-family: 'Godzilla';
    color: rgba(254, 199, 61, 0.988);
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    position:absolute;
    width: 80%;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%)
}

.selectable-dragons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.push-down-background{
    padding-top: 100px;
}

</style>