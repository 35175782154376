import { firebase } from '@firebase/app'
import 'firebase/firestore'

const getFirebase = () => {
    if (firebase.apps.length === 0) {
        firebase.initializeApp({
             apiKey: 'AIzaSyD1t5SFN7GSGc3Ji3Unw26MgHljf9TnDig',
             authDomain: 'tiny-dragons.firebaseapp.com',
             projectId: 'tiny-dragons'
         });
     }

    return firebase;
}

export default getFirebase();