<template>
    <div>
        <ArenaScene />
    </div>
</template>
<script>

import ArenaScene from '../components/ArenaScene'

export default {
  name: 'Arena',
  props: {
    currentUser: {},
  },
  components: {
      ArenaScene
  },
  methods: {
    hideNavBar(){
        this.$emit('hide-navbar')
    }
  },
  mounted(){
    this.hideNavBar()
  }
}
</script>