<template>
    <div>
        <div class="background-wrapper">
            <div :class="'background-distance ' + this.distanceBackground ">
            </div>
            <div :class="'background ' + this.trees">
                <div class="tree one">
                </div>
                <div class="tree two">                
                </div>
                <div class="tree three">
                </div>
            </div>
            <div :class="'ground-layer ' + this.ground"></div>
        </div>
        <BottomToggles
            :sfxEnabled="sfxEnabled"
            :musicEnabled="musicEnabled"
            :backButtonClass="backButtonClass"
            @sfx-switch="$emit('sfx-switch')"
            @music-switch="$emit('music-switch')"
        />
    </div>
</template>
<script>

import BottomToggles from '../components/BottomToggles'
export default {
    name: 'ArenaScene',
    props: {
        distanceBackground:{
            type: String
        },
        ground:{
            type: String
        },
        trees:{
            type: String
        },
        sfxEnabled:{
            type: Boolean
        },
        musicEnabled:{
            type: Boolean
        },
        backButtonClass:{
            type: String
        }
    },
    components: {
        BottomToggles
    }
  }

</script>
<style scoped>

    @keyframes tree-wind {
        from{
            transform: skewX(0);
        }

        50%{
            transform: skewX(2deg);
        }

        to{
            transform: skewX(0);
        }
    }

    .background{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .background-distance{
        background: url('/img/mountains-bg.png') repeat-x bottom left;
        width: 100%;
        height: 70%;
        position: absolute;
    }

    .mountains-lower {
        height: 80%;
    }

    .tree{
        transform-origin: bottom left;
        max-width: 400px;
        position: absolute;
        animation: tree-wind ease-in-out 5s 1.5s infinite;
    }

    .tree.one{
        width: 350px;
        height: 360px;

        background: url('/img/tree-1.png') no-repeat top left;
        background-size: contain;
    }

    .tree.two{
        width: 410px;
        height: 600px;

        background: url('/img/tree-2.png') no-repeat top left;
        background-size: contain;
    }

    .tree.three{
        width: 360px;
        height: 410px;
        bottom: 249px;

        background: url('/img/tree-3.png') no-repeat top left;
        background-size: contain;
    }

    .tree:nth-child(1){
        left: 40%;
        max-width: 350px;
        bottom: 210px;
    }

    .tree:nth-child(2){
        left: 70%;
        max-width: 550px;
        top: -20px;
    }

    .ground-layer{
        background: url('/img/arena-bg-1.png') repeat-x bottom left;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .ground-lower {
        height: 110%;
    }

    .hide-items {
        visibility: hidden;
    }

</style>