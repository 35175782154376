<template>
  <h2 class="page-heading">Dragon Fire Lottery</h2>
  <div class="container">
        <div v-if="this.hasLotteryStarted">
          <h3 class="highlight">Lottery Draws ~{{ (new Date(this.countdownTimestampDraws * 1000)).toString() }}</h3>
          <div v-if="this.countdownTimestampStart > this.currentTimestamp">
        <!-- <div> -->
            <h3>Lottery Entry Opens</h3>
            <Countdown :date="this.countdownTimestampStart" />
        </div>
        <div v-else-if="(this.countdownTimestampStart <= this.currentTimestamp  ) && (this.currentTimestamp < this.countdownTimestampEnd )">
        <!-- <div> -->
            <h2>Lottery Opened!</h2>
            <div class="enter-container">
                <div class="entries-end">
                    <h4>Time until entries close:</h4>
                    <Countdown :date="this.countdownTimestampEnd" />
                   
                </div>
                <div class="entries-enter">
                    <h3 class="entry-count" v-if="this.currentEntries.length > 0">You have {{this.currentEntries.length}} entry(s).</h3>
                    <p>Price: ${{this.costInStables}} - <strong>{{ ( ( this.entryCostInDcau.div(10**18)) ).toFixed(2) }} DCAU </strong><br/>BAL: <strong>{{ this.dcauBalance.div( (10**18) ).toFixed(2) }} DCAU</strong></p>
                    <p class="error" v-if="this.isError">
                        {{this.errorMessage}}
                    </p>
                    <Button v-if="this.hasApproved" :enabled="true" @btn-click="enterLottery()" :text="'Enter Lottery! ' + (this.entryCostInDcau.div(10**18)).toFixed(2) + 'DCAU'" />
                    <Button v-else :text="'Approve DCAU'" @btn-click="approveUser" :color="'primary'" :enabled="!this.isError" />

                    <p class="buy"><a href="https://www.traderjoexyz.com/#/trade?outputCurrency=0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30" target="_blank">Buy DCAU here</a></p>
                </div>
            </div>
        </div>
        <div v-else>
            <h2>Entries Closed!</h2>
            
            <p>Entries are closed, if you've entered you can wait until the draw time to see if you've won.</p>
            <p v-if="this.currentEntries.length > 0"><span class="" >You have {{this.currentEntries.length}} entry(s).</span></p>
        </div>
        <div class="win">
            <div class="prize-money">
                <div>
                    <h2>Winning Entry Gets:</h2>
                    <h3>Token Prize: {{ ( ( this.currentPrizeTokenAmount / (10**this.currentPrizeTokenDecimals)) ).toFixed(2) + ' ' + this.currentPrizeToken }}</h3>
                    <div class="image">
                        <img :src="'https://aurum.dragoncrypto.io/assets/images/tokens/' + this.currentPrizeToken.toLowerCase() + '.png'" :alt="this.currentPrizeToken" />
                    </div>
                </div>
            </div>
            <div class="current-prize card">
                <h2>Prize Tiny Dragon #{{this.currentPrizeId}}</h2>
                <div class="image"><img :src="'/dr/g1/' + this.currentPrizeId + '.png'" :alt="'Tiny Dragon #' + this.currentPrizeId" /></div>
                <div class="level">
                    <h3>Level {{ this.lotteryPrizeLevel}}</h3>
                </div>
                <div class="stats">
                    <ul>
                        <li><div class="img-box"><img src="/img/attack-icon.png" alt="Attack" /></div> <div class="text">{{ this.lotteryPrizeAttack }} Attack</div></li>
                        <li><div class="img-box"><img src="/img/rank-icon.png" alt="Rank" /></div> <div class="text">{{ this.lotteryPrizeRank }}/1000 Rank</div></li>
                        <li><div class="img-box"><img src="/img/defense-icon.png" alt="Defense" /></div> <div class="text">{{ this.lotteryPrizeDefense }} Defense</div></li>
                    </ul>
                </div>
            </div>
        </div>
        
      </div>
      <div class="auto-margin" v-else>
          <h3>There aren't any lotteries running right now</h3>
          <p>But we post them daily so come back again to check the page!</p>
      </div>
     
  </div>
  <div class="winners-table">
      <div v-if="this.previousWinners.length > 0">
          <h3>Previous Winners</h3>
          <table>
              <thead>
                  <tr>
                      <th>Tiny Dragon Won</th>
                      <th>Winner Address</th>
                      <th>Tokens Won</th>
                      <th>Players Entered</th>
                      <th>Transaction</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="prevWinner in this.previousWinners" :key="prevWinner.tokenId">
                      <td><router-link :to="'/view?id=' + prevWinner.tokenId">#{{prevWinner.tokenId}}</router-link></td>
                      <td>{{ prevWinner.address }}</td>
                      <td>{{ prevWinner.tokenTotal }} {{ prevWinner.tokenSymbol }}</td>
                      <td>{{ prevWinner.totalEntries }}</td>
                      <td><a :href="'https://snowtrace.io/tx/' + prevWinner.txHash" target="_blank">View</a></td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
  <div :class="showLoading ? 'loading-cloud' : 'loading-cloud hide'">
    <div class="close" @click="hideLoader()">Close</div>
    <div class="element bounce">
      <p>Processing...</p>
    </div>
  </div>
</template>

<script>
import erc20Abi from '../abi/erc20.json'
import fireLotteryAbi from '../abi/fireLottery.json'
import arenaAbi from '../abi/tinyDragonArena.json'
import Web3 from 'web3'
import constants from '../consts/constants'
import Countdown from '../components/Countdown'
import Button from '../components/Button'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

export default {
  name: 'Lottery',
  props: {
    currentUser: {}
  },
  components:{
      Countdown,
      Button
  },
  data(){
    return {
      showLoading: false,
      currentRoundId: 0,
      hasApproved: false,
      hasLotteryStarted: false,
      currentPrizeId: 0,
      currentPrizeTokenAddress: '',
      currentPrizeToken: '',
      currentPrizeTokenDecimals: 18,
      currentPrizeTokenAmount: 0,
      countdownTimestampStart: 0,
      countdownTimestampEnd: 0,
      countdownTimestampDraws: 0,
      entryCostInDcau: new BigNumber(0),
      lotteryPrizeRank: 0,
      lotteryPrizeLevel: 1,
      lotteryPrizeAttack: 1,
      lotteryPrizeDefense: 1,
      currentTimestamp: Math.trunc((new Date()).getTime() / 1000),
      costInStables: 1,
      dcauBalance: new BigNumber(0),
      isError: false,
      errorMessage: '',
      previousWinners: [],
      currentEntries: [],
      entriesCount: 0
    }
  },
  methods: {
    hideLoader(){
       this.showLoading = false
    },
    refreshEntries( hideShowLoader = false ){
         const web3 = new Web3(window.ethereum)
         const fireLotteryContract = new web3.eth.Contract(fireLotteryAbi, constants.fireLotteryContract)
         fireLotteryContract.getPastEvents("LotteryEntered",
            {                               
                fromBlock: 1830000,     
                toBlock: 'latest' // You can also specify 'latest'          
            })                              
          .then(events => { 
              this.currentEntries = []
              for (let index = 0; index < events.length; index++) {
                  const element = events[index];
                  
                  if (element.returnValues.user.toLowerCase() === this.currentUser.walletAddress.toLowerCase() && element.returnValues.roundId == this.currentRoundId ) {
                      this.currentEntries.push( true )
                  }
              }

              if( hideShowLoader )
              {
                this.showLoading = false
              }
          })
    },
    enterLottery(){
        this.showLoading = true

        const web3 = new Web3(window.ethereum)
        const fireLotteryContract = new web3.eth.Contract(fireLotteryAbi, constants.fireLotteryContract)

        fireLotteryContract.methods.enterLottery( )
            .send({ from: this.currentUser.walletAddress })
            .then( () => { 
                this.refreshEntries()

                if( this.showLoading )
                {
                    this.showLoading = false
                }
            })
    },
    getJSON( url, callback ){
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'json'
        xhr.onload = function() {
            var status = xhr.status
            if (status === 200) {
                callback(xhr.response)
            } else {
                callback(xhr.response)
            }
        }
        xhr.send()
    },
    approveUser(){
        this.showLoading = true
        const web3 = new Web3(window.ethereum);
        const dcauContract = new web3.eth.Contract(erc20Abi, constants.dcauContract);

        dcauContract.methods.approve(constants.fireLotteryContract, ethers.constants.MaxUint256)
            .send({ from: this.currentUser.walletAddress })
            .then( r => {
                this.showLoading = false
                if( r.status )
                {
                    this.hasApproved = true
                }
            } )
        },
    }, 
    async mounted(){

        const web3 = new Web3(window.ethereum)

        const fireLotteryContract = new web3.eth.Contract(fireLotteryAbi, constants.fireLotteryContract)

        fireLotteryContract.getPastEvents("LotteryDrawn",
            {                               
                fromBlock: 1830000,     
                toBlock: 'latest' // You can also specify 'latest'          
            })                              
            .then(events => { 
                for (let index = 0; index < events.length; index++) {
                    const element = events[index];
                    
                    const address = element.returnValues.winner
                    const tokenAddress = element.returnValues.prizeCashAddress
                    const tokenAmt = new BigNumber( element.returnValues.prizeCashAmount )
                    const tokenId = element.returnValues.tokenId
                    const totalEntries = element.returnValues.totalRoundPlayers

                    const txHash = element.transactionHash;

                    let hasMatch = false

                    this.previousWinners.forEach(prev => {
                        if (prev.txHash == txHash) {
                            hasMatch = true
                        }
                    })

                    if (!hasMatch) {

                        // let tokenDecimals = 0
                        let tokenSymbol = "N/A"
                        let tokenTotal = new BigNumber(0)

                        if( !(new BigNumber(tokenAddress)).isZero() )
                        {
                            const erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)

                            erc20Contract.methods.decimals().call().then( r=> {
                                // tokenDecimals = r
                                tokenTotal = tokenAmt.div( 10 ** r )

                                erc20Contract.methods.symbol().call().then( r => {
                                    tokenSymbol = r

                                    this.previousWinners.push({ address, tokenId, tokenTotal, tokenAddress, txHash, tokenSymbol, totalEntries })
                                })

                                
                            })
                            
                            
                        } else {
                            this.previousWinners.push({ address, tokenId, tokenTotal, tokenAddress, txHash, tokenSymbol, totalEntries })
                        }

                        
                    }
                }
            })

        window.setInterval( () =>{
            this.currentTimestamp = Math.trunc((new Date()).getTime() / 1000)

            const web3 = new Web3(window.ethereum)

            const dcauContract = new web3.eth.Contract(erc20Abi, constants.dcauContract)

            dcauContract.methods.allowance( this.currentUser.walletAddress,constants.fireLotteryContract ).call().then( r => {
                if( r > 0 )
                {
                    this.hasApproved = true
                }
            })

            if( this.hasLotteryStarted )
            {
                

                fireLotteryContract.methods.entryCostInNative().call().then( r =>{
                    this.entryCostInDcau = new BigNumber( r )
                } )

                dcauContract.methods.balanceOf( this.currentUser.walletAddress ).call().then( r => {
                    this.dcauBalance = new BigNumber( r )
                    if (this.dcauBalance.lt( this.entryCostInDcau )) {
                        this.isError = true
                        this.errorMessage = "You do not have enough DCAU to enter."
                    } else {
                        this.isError = false
                        this.errorMessage = ''
                    }
                })
            }        
        }, 1000)

        

        this.showLoading = true
        
        this.hasLotteryStarted = await fireLotteryContract.methods.hasCurrentRoundStarted().call()
        this.currentRoundId = await fireLotteryContract.methods.getCurrentRoundId().call()
        this.currentPrizeId = await fireLotteryContract.methods.lotteryPrizeTinyDragonId().call()

        this.costInStables = await fireLotteryContract.methods.entryCostInStables().call()

        if( ( this.currentPrizeId * 1) !== 0)
        {
            const arenaContract = new web3.eth.Contract( arenaAbi, constants.arenaContract )

            this.getJSON( '/json/g1/' + this.currentPrizeId + '.json', ( dragonJson ) => { 
        
                for (let index = 0; index < dragonJson.attributes.length; index++) {
                    const element = dragonJson.attributes[index]
                    
                    if (element.trait_type === 'Rank') {
                        this.lotteryPrizeRank = element.value * 1
                    } 
                }
                
            });

            const stats = await arenaContract.methods.getStats( this.currentPrizeId ).call()

            this.lotteryPrizeLevel = stats.level
            this.lotteryPrizeAttack = stats.attack
            this.lotteryPrizeDefense = stats.defense
        }
        
        const hasCashPrize = await fireLotteryContract.methods.lotteryHasCashPrize().call()

        if( hasCashPrize)
        {
            try {
                this.currentPrizeTokenAddress = await fireLotteryContract.methods.lotteryCashPrizeAddress().call()

                const erc20Contract = new web3.eth.Contract(erc20Abi, this.currentPrizeTokenAddress)

                this.currentPrizeTokenDecimals = await erc20Contract.methods.decimals().call()
                this.currentPrizeToken = await erc20Contract.methods.symbol().call()
                this.currentPrizeTokenAmount = await fireLotteryContract.methods.lotteryCashPrizeAmount().call()
              } catch (error) {
                console.log(error)
            }
        }

        this.countdownTimestampStart = await fireLotteryContract.methods.lotteryStartTimestamp().call()
        this.countdownTimestampEnd = await fireLotteryContract.methods.lotteryClosesTimestamp().call()
        this.countdownTimestampDraws = await fireLotteryContract.methods.lotteryDrawsTimestamp().call()

        this.refreshEntries(true)

        this.showLoading = false
    }
}

</script>
<style scoped>
    .win{
        display: flex;
    }

    .winners-table{
        width: 80%;
        margin: 0 auto;
    }

    .winners-table table{
        margin: 0 auto;
        border-spacing: 0;
        margin-bottom: 16px;
    }

    .winners-table table th{
        background: rgba(255,255,255,0.7);
    }

    .winners-table table th, .winners-table table td{
        padding: 12px 8px;
        text-align: left;
        border: 1px solid rgba(255,255,255,0.7);
    }

    .auto-margin{
        margin: 0 auto;
    }

    .container .prize-money{
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container .prize-money .image img{
        max-width: 200px;
    }

    .container .win .prize-money{
        width: 61%;
        flex-grow: 1;
    }

    .container .win .prize-money h3{
        font-size: 32px;
        margin-top: 8px;
        margin-bottom: 8px;
        color: #0c7b97;
    }

    .highlight{
        color: #0c7b97;
    }

    .entry-count{
        margin-top: -18px;
        margin-bottom: 8px;
        color: #0c7b97;
    }

    .container .win .card{
        width: 38%;
    }

    .error{
      color: #ff1111;
    }

    .enter-container{
        display: flex;
    }

    .enter-container .entries-end, .enter-container .entries-enter{
        flex-grow: 1;
        width: 48%;
    }
</style>