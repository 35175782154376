<template>
    <div class="fight-container">
        <!-- PRE LOAD DRAGON IMAGES -->
        <img v-show="false" :src="'/dr/g1/trans/' + item.tokenId + 'trans.png'" alt="" v-for="item in this.players" :key="item.tokenId">
        
        <DragonModal :showClose="false" :modalActive="showLoading">
            <div class="modal-content pulse">
                <div class="modal-stone-text-holder">
                    <img class="modal-stone" alt="Arena Banner" src="/img/long-button.png">
                    <div class="modal-text">MATCH LOADING</div>
                </div>
            </div>
        </DragonModal>
        <ArenaScene
        :sfxEnabled="sfxEnabled"
        :musicEnabled="musicEnabled"
        :backButtonClass="hideItems"
        @sfx-switch="$emit('sfx-switch')"
        @music-switch="$emit('music-switch')"
        />
        <div class="dragon-fight">
            <div class="wrapper">
                <div :class="'side left ' + (this.dragon1Miss ? 'miss-shot' : '') + (this.dragon1Hit ? ' kill-shot' : '') + (this.dragon1Killed ? ' death-hit' : '') + (this.hideDragon1 ? ' hide' : '' )">
                    <img :src="'/dr/g1/trans/' + this.currentMatchReport.dragon1 + 'trans.png'" alt="50" class="fighter dragon-1" />   
                </div>
                <div :class="'side right ' + (this.dragon2Miss ? 'miss-shot' : '') + (this.dragon2Hit ? ' kill-shot' : '') + (this.dragon2Killed ? ' death-hit' : '') + (this.hideDragon2 ? ' hide' : '' )">
                    <img :src="'/dr/g1/trans/' + this.currentMatchReport.dragon2 + 'trans.png'" alt="913" class="fighter dragon-2" />  
                </div>
            </div>
        </div>
        <div class="stats">
            <div class="left">
                <div class="box">
                    <h3>#{{this.currentMatchReport.dragon1}} LVL {{this.currentMatchReport.dragon1Level}}</h3>
                    <ul>
                        <li><div class="img-box"><img src="/img/attack-icon.png" alt="Attack" /></div> <div class="text">{{ this.currentMatchReport.dragon1Attack }} Attack</div></li>
                        <li><div class="img-box"><img src="/img/defense-icon.png" alt="Defense" /></div> <div class="text">{{ this.currentMatchReport.dragon1Defense }} Defense</div></li>
                    </ul>
                </div>
            </div>
            <div class="right">
                <div class="box">
                    <h3>#{{this.currentMatchReport.dragon2}} LVL {{this.currentMatchReport.dragon2Level}}</h3>
                    <ul>
                        <li><div class="img-box"><img src="/img/attack-icon.png" alt="Attack" /></div> <div class="text">{{ this.currentMatchReport.dragon2Attack }} Attack</div></li>
                        <li><div class="img-box"><img src="/img/defense-icon.png" alt="Defense" /></div> <div class="text">{{ this.currentMatchReport.dragon2Defense }} Defense</div></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hit-miss-messages">
            <div class="left">
                <h3 v-if="this.showDragon2MissText" class="miss-text">MISS!</h3>
                <h3 v-if="this.showDragon2HitText" class="hit-text">BANG!</h3>
            </div>
            <div class="right">
                <h3 v-if="this.showDragon1MissText" class="miss-text">MISS!</h3>
                 <h3 v-if="this.showDragon1HitText" class="hit-text">BANG!</h3>
            </div>
        </div>        
        <div class="messaging">
            <h1 v-show="!this.hasVictor" :class="this.fadeOutMessage ? 'fadeOut' : ''">VS</h1>
            <h1 v-if="this.hasVictor" class="victory">#{{ this.currentMatchReport.isDragon1Winner ? this.currentMatchReport.dragon1 : this.currentMatchReport.dragon2 }} is the Winner!</h1>
        </div>
    </div>
    <DragonModal :showClose="true" @close="toggleModal" :modalActive="modalActive">
        <div class="modal-content">
            <div class="modal-stone-text-holder">
                <img class="modal-stone" alt="Arena Banner" src="/img/long-button.png">
                <div class="modal-text">MATCH RESULTS</div>
            </div>
            <div class="text-results">
                <ul>
                    <li v-for="item in this.textReport" :key="item.contents" :class="item.style">{{ item.contents }}</li>
                </ul>
            </div>
        </div>
    </DragonModal>
</template>
<script>

import Web3 from 'web3'
import ArenaScene from '../components/ArenaScene'
import tinyDragonAbi from '../abi/tinyDragon.json'
import constants from '../consts/constants'
import arenaAbi from '../abi/tinyDragonArena.json'
import erc20Abi from '../abi/erc20.json'
import DragonModal from '../components/DragonModal'
import { firebase } from '@firebase/app'
import 'firebase/firestore'
import { ref } from "vue"

const hit = new Audio('/sounds/music/hit.wav')
const miss = new Audio('/sounds/music/miss.wav')
const victory = new Audio('/sounds/music/victory.wav')


export default {
  name: 'ArenaFightReport',
  inheritAttrs: false,
  props: {
    currentUser: {},
    sfxEnabled: Boolean,
    musicEnabled: Boolean,
    matches: Array
  },
  components: {
      ArenaScene,
      DragonModal
  },
  setup(){
    const modalActive = ref(false);
    const toggleModal = () => {
        modalActive.value = !modalActive.value;
    };
    return { modalActive, toggleModal };
  },
  data() {
    return{ 
        textReport: [],
        hideItems: "hide-items",
        fadeOutMessage: false,
        dragon1Miss: false,
        showDragon1MissText: false,
        dragon2Miss: false,
        showDragon2MissText: false,
        dragon1Hit: false,
        showDragon2HitText: false,
        showDragon1HitText: false,
        dragon2Hit: false,
        dragon1Killed: false,
        dragon2Killed: false,
        hasVictor: false,
        hideDragon1: false,
        hideDragon2: false,
        isFightReady: false,
        web3: {},
        currentMatchReport: {},
        players: [],
        match: {},
        arenaContract: {},
        matchData: [],
        matchPlayerData: [],
        showLoading: true,
        sfxOnScreen: false
    }
  },
  methods: {
    hideNavBar(){
        this.$emit('hide-navbar')
    },
    playHit(){
        if(this.sfxEnabled && this.sfxOnScreen){
            hit.currentTime = 0
            hit.play()
        }
    },
    playMiss(){
        if(this.sfxEnabled && this.sfxOnScreen){
            miss.currentTime = 0
            miss.play()
        }
    },
    playVictory(){
        if(this.sfxEnabled && this.sfxOnScreen){
            victory.currentTime = 0
            victory.play()
        }
    },
    stopSfxSounds(){
        hit.pause()
        hit.currentTime = 0
        miss.pause()
        miss.currentTime = 0
        victory.pause()
        victory.currentTime = 0
    },
    runMatchReport(){
        const delayBetweenHits = 3000
        this.showLoading = false
        let index = 0
        let pairIndex = 0
        let currentPair = this.currentMatchReport.pairsInOrder[pairIndex]
        let lastMatch = false

        this.currentMatchReport.dragon1 = currentPair.dragon1
        this.currentMatchReport.dragon1Level = currentPair.dragon1Level
        this.currentMatchReport.dragon1Attack = currentPair.dragon1Attack
        this.currentMatchReport.dragon1Defense = currentPair.dragon1Defense 

        this.currentMatchReport.dragon2 = currentPair.dragon2
        this.currentMatchReport.dragon2Level = currentPair.dragon2Level
        this.currentMatchReport.dragon2Attack = currentPair.dragon2Attack
        this.currentMatchReport.dragon2Defense = currentPair.dragon2Defense 

        const matchInterval = setInterval( ( ) =>{
            if(!lastMatch){
                this.hideDragon2 = false
                this.dragon2Killed = false
                this.hideDragon1 = false
                this.dragon1Killed = false
            }

            if(this.hasVictor){
                if(pairIndex < this.currentMatchReport.pairsInOrder.length){
                    pairIndex++
                    currentPair = this.currentMatchReport.pairsInOrder[pairIndex]

                    if(pairIndex === this.currentMatchReport.pairsInOrder.length-1){
                        lastMatch = true
                    }

                    this.currentMatchReport.dragon1 = currentPair.dragon1
                    this.currentMatchReport.dragon1Level = currentPair.dragon1Level
                    this.currentMatchReport.dragon1Attack = currentPair.dragon1Attack
                    this.currentMatchReport.dragon1Defense = currentPair.dragon1Defense 

                    this.currentMatchReport.dragon2 = currentPair.dragon2
                    this.currentMatchReport.dragon2Level = currentPair.dragon2Level
                    this.currentMatchReport.dragon2Attack = currentPair.dragon2Attack
                    this.currentMatchReport.dragon2Defense = currentPair.dragon2Defense    
                }else{
                    this.playVictory()
                    this.toggleModal()
                    this.hideItems = ""
                    clearInterval(matchInterval)
                }
            }
            if( index < this.currentMatchReport.matchHits.length )
            {
                this.hasVictor = false
                const matchHit = this.currentMatchReport.matchHits[index]
                
                if (matchHit.isDragon1Turn) {
                    if (matchHit.hasDragon1Hit) {
                        this.dragon1Hit = true
                        this.playHit()

                        setTimeout( () => {
                            this.showDragon1HitText = true
                            this.dragon2Killed = true
                            this.hasVictor = true
                            this.currentMatchReport.isDragon1Winner = true
                        }, 400)

                        setTimeout( () => {
                            this.dragon1Hit = false
                            this.showDragon1HitText = false
                            this.hideDragon2 = true
                        }, 1000)
                    } else {
                        this.dragon1Miss = true
                        this.playMiss()

                        setTimeout( () => {
                            this.showDragon1MissText = true
                        }, 400)

                        setTimeout( () => {
                            this.dragon1Miss = false
                            this.showDragon1MissText = false
                        }, 1000)
                    }
                } else {
                    if (matchHit.hasDragon2Hit) {
                        this.dragon2Hit = true
                        this.playHit()

                        setTimeout( () => {
                            this.showDragon2HitText = true
                            this.dragon1Killed = true
                            this.hasVictor = true
                            this.currentMatchReport.isDragon1Winner = false
                        }, 400)

                        setTimeout( () => {
                            this.dragon2Hit = false
                            this.showDragon2HitText = false
                            this.hideDragon1 = true
                        }, 1000)
                    } else {
                        this.dragon2Miss = true
                        this.playMiss()

                        setTimeout( () => {
                            this.showDragon2MissText = true
                        }, 400)

                        setTimeout( () => {
                            this.dragon2Miss = false
                            this.showDragon2MissText = false
                        }, 1000)
                    }
                }

                index++
            }
        }, delayBetweenHits)
        
    },
    setPlayers(currentPlayers){
        let dragonPair = {}
        let dragon1 = {}
        let dragon2 = {}
        for(const player of this.players){
            if(player.tokenId == currentPlayers[0]){
                
                dragon1 = {
                            dragon1 : player.tokenId,
                            dragon1Level : player.currentLevel,
                            dragon1Attack : player.currentAttack,
                            dragon1Defense : player.currentDefense
                        }
            }

            if(player.tokenId == currentPlayers[1]){
                dragon2 = {
                            dragon2 : player.tokenId,
                            dragon2Level : player.currentLevel,
                            dragon2Attack : player.currentAttack,
                            dragon2Defense : player.currentDefense
                        }
            }
        }
        dragonPair = {...dragon1, ...dragon2}
        this.currentMatchReport.pairsInOrder.push(dragonPair)
    },
    getStyleForNumber( currentNumber )
    {
        let numberToProcess = currentNumber * 1;
        switch (numberToProcess) {
            case 1:
                return "first";
            case 2:
                return "second";
            case 3:
                return "third";
            default:
                break;
        }

        return "";
    },
    getPlacingForNumber( currentNumber )
    {
        let numberToProcess = currentNumber * 1;
        switch (numberToProcess) {
            case 1:
                return "1st";
            case 2:
                return "2nd";
            case 3:
                return "3rd";
            default:
                break;
        }

        return numberToProcess + "th";
    },
    mapMatchData(){
        this.currentMatchReport.matchId = this.$route.query.matchId
        this.currentMatchReport.matchHits = []
        this.currentMatchReport.pairsInOrder = []
        for (const step of this.matchData){
            if(step.includes("VS")){
                const currentPlayers = step.split("VS");
                this.setPlayers(currentPlayers)
            }else if(step.includes("K")){
                const winner = step.split("K")[0];
                const currentDragonPair = this.currentMatchReport.pairsInOrder[this.currentMatchReport.pairsInOrder.length-1]

                this.textReport.push( 
                    { 
                        style: 'win', 
                        contents: "#" + winner + " destroyed #" 
                            + (currentDragonPair.dragon1 === winner ? currentDragonPair.dragon2 : currentDragonPair.dragon1 ) 
                    })

                this.currentMatchReport.matchHits.push({
                        hasDragon1Hit: currentDragonPair.dragon1 === winner,
                        hasDragon2Hit: currentDragonPair.dragon2 === winner,
                        isDragon1Turn: currentDragonPair.dragon1 === winner,
                    })
            }else if(step.includes("M")){
                const missed = step.split("M")[0];
                const currentDragonPair = this.currentMatchReport.pairsInOrder[this.currentMatchReport.pairsInOrder.length-1]
                this.currentMatchReport.matchHits.push({
                        hasDragon1Hit: currentDragonPair.dragon1 === false,
                        hasDragon2Hit: currentDragonPair.dragon2 === false,
                        isDragon1Turn: currentDragonPair.dragon1 === missed,
                })
            }
        }
    }
  },
  created(){
    this.hideNavBar()
    this.web3 = new Web3(window.ethereum)
    this.tinyDragonsContract = new this.web3.eth.Contract(tinyDragonAbi, constants.tinyDragonsContract)
    this.currentMatchReport.matchId = this.$route.query.matchId
    this.arenaContract = new this.web3.eth.Contract(arenaAbi, constants.arenaContract)

  },
  mounted(){
    this.$emit("arena-music")
    this.sfxOnScreen = true

    window.setTimeout( () => {
        this.arenaContract.getPastEvents("GameLevelUp",
        {                               
            filter: { matchId: this.match.matchId },
            fromBlock: constants.arenaStartBlock,     
            toBlock: 'latest' // You can also specify 'latest'          
        })                              
        .then(levelUpEvents => {
            for (let index = 0; index < levelUpEvents.length; index++) {
                const levelUp = levelUpEvents[index];
                if( levelUp.returnValues.matchId == this.match.matchId )
                {

                    this.textReport.push({
                        style: "level",
                        contents: "#" + levelUp.returnValues.tokenId + " GAINED A LEVEL!"
                    });
                }
            }
        })

        this.arenaContract.getPastEvents("PrizeWon",
        {                               
            filter: { matchId: this.match.matchId },
            fromBlock: constants.arenaStartBlock,     
            toBlock: 'latest' // You can also specify 'latest'          
        })                              
        .then(events => {
            for (let index = 0; index < events.length; index++) {
                const element = events[index];

                if( element.returnValues.matchId === this.match.matchId )
                {
                    const currentErc20Contract = new this.web3.eth.Contract( erc20Abi, element.returnValues.token );
                    
                    currentErc20Contract.methods.decimals().call().then( r =>{
                        let amount = element.returnValues.amount;
                        const decimalsAmount = amount / (10**r);
                        
                        currentErc20Contract.methods.symbol().call().then( s => {
                            this.textReport.push({
                                style: this.getStyleForNumber( element.returnValues.placing ),
                                contents: "#" + element.returnValues.tokenId + " was " + this.getPlacingForNumber( element.returnValues.placing )
                                    + " and won " + decimalsAmount + " " + s
                            });
                        })
                    })
                }
            }
        })
    },3000);

    this.arenaContract.methods.matchInfo(this.currentMatchReport.matchId).call().then(matchInfo => {
        this.match = matchInfo   

        if (firebase.apps.length === 0) {
           firebase.initializeApp({
                apiKey: 'AIzaSyD1t5SFN7GSGc3Ji3Unw26MgHljf9TnDig',
                authDomain: 'tiny-dragons.firebaseapp.com',
                projectId: 'tiny-dragons'
            });
        }

        const db = firebase.firestore();

        const matchLogsRef = db.collection('matchlogs');

        matchLogsRef.where( "matchId", "==", this.match.matchId + "" ).limit(1).get().then( query => {
            const data = query.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            if( data.length > 0 )
            {
                this.matchData = data[0].data

                this.arenaContract.methods.getMatchStats( this.match.matchId ).call().then( matchStats => {
                    for (let index = 0; index < matchStats.length; index++) {
                        const element = matchStats[index];

                        this.players.push({
                            tokenId: this.match.matchTokenIds[index],
                            currentLevel: element.level,
                            currentAttack: element.attack,
                            currentDefense: element.defense
                        })
                        
                    }

                    this.mapMatchData()

                    window.setTimeout( ()=> {
                        this.fadeOutMessage = true
                        this.isFightReady = true
                    }, 2000)

                    window.setInterval( () => {
                        if( this.isFightReady )
                        {
                            this.isFightReady = false
                            this.runMatchReport()
                        }
                    }, 2000)
                })
            } else {
                this.arenaContract.methods.matchReport( this.currentMatchReport.matchId ).call().then( r => {
                    this.matchData = r;

                    this.arenaContract.methods.getMatchStats( this.match.matchId ).call().then( matchStats => {
                        for (let index = 0; index < matchStats.length; index++) {
                            const element = matchStats[index];

                            this.players.push({
                                tokenId: this.match.matchTokenIds[index],
                                currentLevel: element.level,
                                currentAttack: element.attack,
                                currentDefense: element.defense
                            })
                            
                        }

                        this.mapMatchData()

                        window.setTimeout( ()=> {
                            this.fadeOutMessage = true
                            this.isFightReady = true
                        }, 2000)

                        window.setInterval( () => {
                            if( this.isFightReady )
                            {
                                this.isFightReady = false
                                this.runMatchReport()
                            }
                        }, 2000)
                    })
                })
            }
        });
    })
  },
  beforeUnmount(){
      this.sfxOnScreen = false
      this.stopSfxSounds()
  }
}
</script>
<style scoped>
    .pulse {
        animation: pulse 2s infinite;
        margin: 0 auto;
        display: table;
        animation-direction: alternate;
        -webkit-animation-name: pulse;
        animation-name: pulse;
    }
        
    @-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
    }
    }
        
    @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
    }

    @keyframes text-explode {
        from{
            transform: scale(1);
            opacity: 1;
        }
        to{
            transform: scale(300%);
            opacity: 0;
        }
    }

    @keyframes death-anim-left {
        from{
            transform: rotate(0) scale(1) scaleX(1);
        }
        to{
            transform: rotate(-180deg) scale(0.25) scaleX(1) translateX(-350%) translateY(-550%);
        }
    }

    @keyframes death-anim-right {
        from{
            transform: rotate(0) scale(1) scaleX(-1);
        }
        to{
            transform: rotate(180deg) scale(0.25) scaleX(-1) translateX(-350%) translateY(-550%);
        }
    }

    @keyframes text-fly-up {
        from{
            transform: translateY(0);
            opacity: 1;
        }
        to{
            transform: translateY(-200%);
            opacity: 0;
        }
    }

    @keyframes idle {
        from {
            transform: scaleY(100%);
        }

        50%{
            transform: scaleY(98%);
        }

        to {
            transform: scaleY(100%);
        }
    }

    @keyframes idle-right {
        from {
            transform: scaleY(100%) scaleX(-1);
        }

        50%{
            transform: scaleY(98%) scaleX(-1);
        }

        to {
            transform: scaleY(100%) scaleX(-1);
        }
    }

    @keyframes kill-shot-left {
        from {
            transform: translateX(0) rotate(0);
        }

        50%{
            transform: translateX(150%) rotate(15deg);
        }

        to{
            transform: translateX(0) rotate(0);
        }
    }

    @keyframes miss-left {
        from {
            transform: translateX(0) rotate(0);
        }

        50%{
            transform: translateX(200%) rotate(15deg);
        }

        to{
            transform: translateX(0) rotate(0);
        }
    }

    @keyframes miss-right {
        from {
            transform: translateX(0) rotate(0) scaleX(-1);
        }

        50%{
            transform: translateX(-200%) rotate(-15deg) scaleX(-1);
        }

        to{
            transform: translateX(0) rotate(0) scaleX(-1);
        }
    }

    @keyframes kill-shot-right {
        from {
            transform: translateX(0) rotate(0) scaleX(-1);
        }

        50%{
            transform: translateX(-150%) rotate(-15deg) scaleX(-1);
        }

        to{
            transform: translateX(0) rotate(0) scaleX(-1);
        }
    }

    .modal-content .text-results{
        background: #808080;
        width: 40%;
        margin: 0 auto;
        color: #fff;
        font-family: 'Godzilla', sans-serif;
        text-align: left;
        font-size: 20px;
        border: 3px solid #666666;
        border-radius: 6px;
    }

    .text-results .first{
        color: #ffed00;
    }

    .text-results .second{
        color: #dedede;
    }

    .text-results .third{
        color: #ffa800;
    }

    .text-results .level{
        color: #54ff00;
    }

    .modal-content .text-results ul, .modal-content .text-results li{
        list-style: none;
    }

     .modal-content .text-results li{
         margin-bottom: 12px;
     }

    .fight-container{
        /* height: calc( 100% - 77.6px ); */
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .messaging, .hit-miss-messages{
        pointer-events: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-family: 'Komika Axis', sans-serif;
        z-index: 1001;
        overflow: hidden;
    }

    .messaging .left, .messaging .right, .hit-miss-messages .left, .hit-miss-messages .right{
        width: 48%;
        color: #fff;
        font-size: 8.3vw;
        -webkit-text-stroke: 3px rgb(0, 75, 145);
    }

    .hit-miss-messages .miss-text{
        animation: text-fly-up 600ms ease-in-out;
    }

    .hit-miss-messages .hit-text{
        animation: text-explode 600ms ease-out;
        -webkit-text-stroke: 3px #c94306;
    }

    .stats{
        position: absolute;
        display: flex;
        align-items: baseline;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 20px;
        font-family: 'Komika Axis', sans-serif;
        pointer-events: none; 
    }

    .stats .left, .stats .right{
        width: 49%;
        height: 170px;
        align-self: flex-end;
        display: flex;
    }

    .stats .left .box{
        margin-left: 20px;
    }

    .stats .right .box{
        margin-left: auto;
    }

    .stats .box{
        background: url('/img/info-bg-wood.png') no-repeat;
        background-size: contain;
        width: 250px;
        height: 170px;
        text-align: center;
        color: rgba(255,255,255,0.9);
        text-shadow:
            -2px -2px 0 #583a1c,
            0   -2px 0 #583a1c,
            2px -2px 0 #583a1c,
            2px  0   0 #583a1c,
            2px  2px 0 #583a1c,
            0    2px 0 #583a1c,
            -2px  2px 0 #583a1c,
            -2px  0   0 #583a1c;
    }

    .stats .box h3{
        font-size: 150%;
        margin: 8px 0;
    }

    .stats .box ul{
        display: flex;
        flex-wrap: wrap;
        padding-left: 12px;
        padding-right: 12px;
        align-items: center;
    }

    .stats .box ul li{
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .stats .box ul li .img-box{
        width: 38px;
        text-align: center;
        margin-right: 5px;
    }

    .stats .box ul li .text{
        padding-bottom: 4px;
    }

    .stats .box ul li img{
        max-height: 38px;
        max-width: 100%;
    }

    .messaging h1{
        margin: 0;
        padding: 0;
        font-size: 8.3vw;
        margin-top: -50px;
        color: #fff;
        -webkit-text-stroke: 3px rgb(0, 75, 145);
        opacity: 1;
        transition: all 1s ease-in-out;
    }

    .messaging h1.fadeOut{
        transform-origin: center center;
        transform: scale(400%);
        opacity: 0;
        transition: all 1s ease-in-out;
    }

    .dragon-fight{
        height: 75%;
    }


    .dragon-fight .wrapper{
        display: flex;
        height: 100%;
    }

    .dragon-fight .side{
        width: 48%;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        align-self: flex-end;
    }

    .dragon-fight .side.left img{
        max-width: 60%;
        transform-origin: bottom;
        animation: idle 2s ease-in-out infinite;
    }

    .dragon-fight .side.right img{
        max-width: 60%;
        transform-origin: bottom;
        transform: scaleX(-1);
        animation: idle-right 2.5s 0.55s ease-in-out infinite;
    }

    .dragon-fight .side.left.miss-shot, .dragon-fight .side.right.miss-shot, 
    .dragon-fight .side.right.kill-shot,.dragon-fight .side.left.kill-shot {
        z-index: 1000;
    }

    .dragon-fight .side.left.miss-shot img{
        animation: miss-left 1s ease-in-out infinite;
    }

    .dragon-fight .side.right.miss-shot img{
        animation: miss-right 1s ease-in-out infinite;
    }

    .dragon-fight .side.left.kill-shot img{
        animation: kill-shot-left 1s ease-in-out infinite;
    }

    .dragon-fight .side.right.kill-shot img{
        animation: kill-shot-right 1s ease-in-out infinite;
    }

    .dragon-fight .side.right.hide img{
        opacity: 0;
    }

    .dragon-fight .side.left.hide img{
        opacity: 0;
    }

    .dragon-fight .side.left.death-hit img{
        animation: death-anim-left 2s ease-in-out;
    }

    .dragon-fight .side.right.death-hit img{
        animation: death-anim-right 2s ease-in-out;
    }

    .dragon-fight .left{
        text-align: left;
    }

    .dragon-fight .right{
        text-align: right;
    }

    /* MOBILE VIEW */

    @media only screen and (max-width: 600px) {
        .dragon-fight{
            height: 66%;
        }

        .messaging{
            height: 80%;
        }

        .messaging h1{
            -webkit-text-stroke: 1px rgb(0, 75, 145);
        }

        .stats .left .box{
            margin-left: 0;
        }

        .stats .right .box{
            margin-left: auto;
        }
        .stats .box ul li .text{
            font-size: 2.5vw;
        }

        .stats .box h3{
            font-size: 2.9vw;
            margin: 8px 0;
        }

        .dragon-fight .side.left img{
            max-width: 70%;
        }

        .dragon-fight .side.right img{
            max-width: 70%;
        }

        .modal-stone {
            width: 50%;
        }

        .modal-text{
            font-size: 5.5vw;
            top: 29%;
        }
    }

    
</style>